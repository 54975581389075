import { MODALS } from "../AppModals/modals.constants";
import APIFailureModal from "./APIFailureModal/APIFailureModal";
import LastNameAmendmentModal from "../Traveler/LastNameAmendmentModal";
import FlightReIssuanceModal from "./FlightReIssuanceModal";
import RefundModal from "./RefundModal";
import SessionExpiredModal from "../../../infrastructure/SessionProvider/SessionExpiredModal";
import BookingCancellationModal from "./FlightCancellationModal";
import AmenitiesModal from "./AmenitiesModal";
import HotelFacilitiesModal from "./HotelFacilityModal/HotelFacilityModal";
import FlightOptionsModal from "./FlightOptionsModal";
import ViewFlightDetailsModal from "./ViewFlightDetailsModal";
import ApprovalFilterModal from "./ApprovalFiltersModal/ApprovalFilterModal";
import CreateAndManageTripModal from "./CreateAndManageTripModal";
import TripPaymentModal from "./TripPaymentModal";
import HotelViewMoreModal from "./HotelViewMoreModal";
import ManagePasswordModal from "./ManagePasswordModal";
import PayLaterModal from "./PayLaterModal";

const {
  API_FAILURE_MODAL,
  LAST_NAME_AMENDMENT_MODAL,
  FLIGHT_REISSUANCE_MODAL,
  REFUND_MODAL,
  SESSION_EXPIRED_MODAL,
  BOOKING_CANCELLATION_MODAL,
  AMENITIES_MODAL,
  HOTEL_FACILITIES_MODAL,
  FLIGHT_OPTIONS_MODAL,
  VIEW_FLIGHT_DETAILS_MODAL,
  APPROVAL_FILTERS_MODAL,
  CREATE_TRIP_MODAL,
  MANAGE_TRIP_MODAL,
  TRIP_PAYMENT_MODAL,
  HOTEL_VIEW_MORE_MODAL,
  MANAGE_PASSWORD_MODAL,
  PAY_LATER_MODAL,
} = MODALS;

const renderModal = (name) =>
  ({
    [LAST_NAME_AMENDMENT_MODAL]: <LastNameAmendmentModal />,
    [API_FAILURE_MODAL]: <APIFailureModal />,
    [FLIGHT_REISSUANCE_MODAL]: <FlightReIssuanceModal />,
    [REFUND_MODAL]: <RefundModal />,
    [SESSION_EXPIRED_MODAL]: <SessionExpiredModal />,
    [BOOKING_CANCELLATION_MODAL]: <BookingCancellationModal />,
    [AMENITIES_MODAL]: <AmenitiesModal />,
    [HOTEL_FACILITIES_MODAL]: <HotelFacilitiesModal />,
    [FLIGHT_OPTIONS_MODAL]: <FlightOptionsModal />,
    [VIEW_FLIGHT_DETAILS_MODAL]: <ViewFlightDetailsModal />,
    [APPROVAL_FILTERS_MODAL]: <ApprovalFilterModal />,
    [CREATE_TRIP_MODAL]: <CreateAndManageTripModal />,
    [MANAGE_TRIP_MODAL]: <CreateAndManageTripModal />,
    [TRIP_PAYMENT_MODAL]: <TripPaymentModal />,
    [HOTEL_VIEW_MORE_MODAL]: <HotelViewMoreModal />,
    [MANAGE_PASSWORD_MODAL]: <ManagePasswordModal />,
    [PAY_LATER_MODAL]: <PayLaterModal />,
  }[name] || null);

export default renderModal;

export { MODALS };
