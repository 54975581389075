import React from 'react'
import { BOOKING_TYPES, DEFAULT_VALUES } from "../../constants"
import { getFormattedLongDate } from '../../helper';

const { VOUCHER_BOOKING, NON_VOUCHER_BOOKING } = BOOKING_TYPES
const { ZERO, ONE, TWO } = DEFAULT_VALUES

const PaymentType = ({ handleOptionChange, voucherBookingType, cancellationPolicies, toShowNonVoucherBooking }) => {
    const policyWithZeroCharge =
        cancellationPolicies.find((policy) => policy.charge === 0) || null;

    return (
        <div className='bg-white rounded-xl border border-contrast-300 pb-2'>
            <div className='px-3 md:px-6 py-4 items-center bg-contrast-50'>
                <h4 className='text-base font-bold pb-4 text-contrast-900 flex-1'>
                    Payment Options
                </h4>
                <div className="flex flex-col gap-4">
                    <div className="checbox-tab">
                        <label className="cursor-pointer relative">
                            <input
                                type="radio"
                                className="peer hidden"
                                onChange={handleOptionChange}
                                name="paymentOption"
                                value={VOUCHER_BOOKING}
                                checked={voucherBookingType === VOUCHER_BOOKING}
                            />
                            <div className="w-4 h-4 shrink-0 rounded-full grid place-content-center  border-[5px] absolute left-3 top-1/2 -translate-y-1/2 peer-checked:border-primary-600"></div>
                            <div className="flex flex-col gap-1 border-2 p-4 rounded-lg pl-10 peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600">
                                <h6 className="text-sm font-semibold flex uppercase">
                                    Make full payment
                                </h6>
                                {policyWithZeroCharge && (policyWithZeroCharge?.charge === ZERO &&
                                    (policyWithZeroCharge.chargeType === TWO || policyWithZeroCharge.chargeType === ONE)) &&
                                    (<span className="text-xs flex">
                                        Cancel for free any time before end of {" "}
                                        {getFormattedLongDate(
                                            policyWithZeroCharge.toDate, {
                                            day: "numeric",
                                            month: "long",
                                            year: "numeric",
                                        }
                                        )}
                                    </span>)
                                }
                            </div>
                        </label>
                    </div>

                    {toShowNonVoucherBooking && (
                        <div
                            className="checbox-tab"
                        >
                            <label className="cursor-pointer relative">
                                <input
                                    type="radio"
                                    className="peer hidden"
                                    onChange={handleOptionChange}
                                    name="paymentOption"
                                    value={NON_VOUCHER_BOOKING}
                                    checked={voucherBookingType === NON_VOUCHER_BOOKING}
                                />
                                <div className="w-4 h-4 shrink-0 rounded-full grid place-content-center  border-[5px] absolute left-3 top-1/2 -translate-y-1/2 peer-checked:border-primary-600"></div>
                                <div className="flex flex-col gap-1 border-2 p-4 rounded-lg pl-10 peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600">
                                    <h6 className="text-sm font-semibold flex uppercase">
                                        Book with ₹0 payment
                                    </h6>

                                    <span className="text-xs flex">
                                        Confirm your booking and Pay Later
                                    </span>
                                </div>
                            </label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PaymentType
