const SMOKING_PREFERENCE_CODES = {
  NoPreference: 0,
  Smoking: 1,
  NonSmoking: 2,
  Either: 3,
};

export const formatRoomInfo = (room) => {
  const {
    roomIndex,
    roomTypeCode,
    roomTypeName,
    ratePlanCode,
    bedTypeCode,
    smokingPreference,
    hotelSupplements,
    price = {},
  } = room;
  const { gst, serviceCharge, tcs, totalGSTAmount, ...filteredpriceInfo } =
    price;

  return {
    roomIndex,
    roomTypeCode,
    roomTypeName,
    ratePlanCode,
    bedTypeCode,
    smokingPreference: SMOKING_PREFERENCE_CODES[smokingPreference],
    supplements: hotelSupplements,
    price: filteredpriceInfo,
  };
};

const getFormattedRoomInfo = (roomInfo) =>
  Array.isArray(roomInfo)
    ? roomInfo.map(formatRoomInfo)
    : [formatRoomInfo(roomInfo)];

export const getPricePolicyReqBody = (
  hotelInfo,
  roomInfo,
  hotelCode,
  endUserIp,
  resultIndex,
  noOfRooms,
  categoryId,
  guestNationality,
  checkInDate,
  checkOutDate,
  sessionId,
) => {
  const {
    traceId,
    hotelDetails: { hotelName },
  } = hotelInfo;
const {REACT_APP_HOTEL_PAY_LATER = false} = process.env;
  let currentDate = new Date();
  let roomData = Array.isArray(roomInfo) ? roomInfo[0] : roomInfo;
  currentDate.setDate(currentDate.getDate() + 1);
  let isBeforeFutureDate = (REACT_APP_HOTEL_PAY_LATER === "true") && currentDate.toISOString() < new Date(roomData.lastVoucherDate).toISOString();

  return {
    resultIndex,
    hotelCode,
    hotelName,
    guestNationality,
    noOfRooms,
    isVoucherBooking: isBeforeFutureDate ? "false" : "true",
    categoryId,
    endUserIp,
    traceId,
    checkInDate,
    checkOutDate,
    hotelRoomsDetails: getFormattedRoomInfo(roomInfo),
    sessionId
  };
};
