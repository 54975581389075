import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedTrip } from "../../Booking/Trips";
import { isEmpty } from "lodash";
import FlightInvoice from "./FlightInvoice";
import HotelInvoice from "./HotelInvoice";
import HotelAndFlightInvoice from "./HotelAndFlightIlnvoice";
import { PAYMENT_STATUS } from "../../../constants";

const { COMPLETED } = PAYMENT_STATUS;

const Invoice = () => {
  const tripDetails = useSelector(selectSelectedTrip);
  const selectedHotelDetail = tripDetails?.hotels[0];
  const paymentStatus = selectedHotelDetail?.paymentStatus;
  const selectedFlightDetail = tripDetails?.flights;

  return (
    <div>
      <div className='bg-white p-6 rounded-lg shadow-md w-full'>
        <div>
          {selectedHotelDetail?.isVoucheredBooking ? (
            <HotelAndFlightInvoice />
          ) : (
            <div>
              {!isEmpty(selectedFlightDetail) && (
                <>
                  <FlightInvoice />
                </>
              )}
              {!isEmpty(selectedHotelDetail) && paymentStatus === COMPLETED && (
                <HotelInvoice printButton={isEmpty(selectedFlightDetail)} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
