import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DEFAULT_VALUES } from "../../../../constants";

const {EMPTY_STRING}=DEFAULT_VALUES;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        size: 12,
        color: "#111827",
      },
    },
    y: {
      ticks: {
        callback: (value) => (Number.isInteger(value) ? value : EMPTY_STRING),
        size: 12,
        color: "#111827",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltips: {
      position: "nearest",
      mode: "index",
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      caretSize: 4,
      backgroundColor: "rgba(72, 241, 12, 1)",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 4,
    },
  },
  maintainAspectRatio: false,
};

const datasetConfig = {
  label: "Dataset",
  borderColor: "#1967d2",
  fill: "start",
};

const LineChart = ({ labels, data, color }) => {
  datasetConfig.borderColor=color;
  const lineChartData = {
    labels,
    datasets: [
      {
        ...datasetConfig,
        data,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(
            0,
            Math.min(...data),
            0,
            Math.max(...data)
          );
          gradient.addColorStop(0, color+40);
          gradient.addColorStop(1, color+40);
          return gradient;
        },
      },
    ],
  };

  return (
    <div className='w-full h-96 flex justify-center'>
      <Line options={options} data={lineChartData} />
    </div>
  );
};

export default LineChart;
