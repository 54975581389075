import reducer from "./booking.reducer";

export const selectSlice = (state) => state[reducer.name];

export const selectAmadeusCancellationCharges = (state) =>
  selectSlice(state).cancellationChargesAM;

export const selectTBOCancellationCharges = (state) =>
  selectSlice(state).cancellationChargesTBO;

export const selectSelectedBooking = (state) =>
  selectSlice(state).selectedBooking;

export const selectProcessPaymentRes = (state) =>
  selectSlice(state).processPaymentResponse;

export const selectUserBookingInfo = (state) =>
  selectSlice(state).userBookingInformation;

export const selectIsGenerateVoucherInitiated = (state) =>
  selectSlice(state).isGenerateVoucherInitiated;

export const selectFlightCancellationCharges = (state) =>
  selectSlice(state).cancellationCharges;

