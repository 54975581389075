import { useState } from "react";
import { useSelector } from "react-redux";
import { RenderSVG, EditFill } from "../../assets/icons";
import ManagePolicyModal from "../../components/organisms/AppModals/ManagePolicyModal";
import { selectCurrentUserInfo } from "../Auth";
import {
  DEFAULT_VALUES,
  AVAILABLE_PERMISSIONS,
  POLICY_CATAGORY,
} from "../../constants";
import { selectCountryInfo } from "../Profile";
import classNames from "classnames";
import { formatPriceWithCommas } from "../../helper";

const { EMPTY_OBJECT } = DEFAULT_VALUES;
const { CAN_EDIT_POLICIES } = AVAILABLE_PERMISSIONS;
const { COST, AIRLINES, RATING } = POLICY_CATAGORY;

const getRequiredPermissions = (authInfo) => {
  const { permissions } = authInfo || EMPTY_OBJECT;
  const requiredPermissions = {
    canEditPolicies: permissions.includes(CAN_EDIT_POLICIES),
  };
  return requiredPermissions;
};

const AirlinesNameBadges = ({ airlinesNames }) => {
  //sorted the airlines names alphabetically
  if (!airlinesNames) return;
  const sortedValues = airlinesNames.toSorted((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });

  return (
    <div className='gap-1 flex flex-wrap'>
      {sortedValues.map(({ name, code }) => (
        <div
          key={code}
          className='flex items-center h-fit text-sm px-2 py-0.5 rounded-full capitalize bg-gray-200 border border-gray-400 shadow-sm transition-all duration-200 transform hover:scale-105 hover:text-blue-500'
        >
          {name}
        </div>
      ))}
    </div>
  );
};

const CostPolicyData = ({ policyValue }) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const { value } = policyValue || EMPTY_OBJECT;
  const { currency = EMPTY_OBJECT } = selectedCountryInfo || EMPTY_OBJECT;
  const { symbol } = currency;

  return (
    <div className='font-medium'>
      <span>{symbol}</span> <span>{formatPriceWithCommas(value)}</span>
    </div>
  );
};

const HotelRatings = ({ ratings }) => {
  const { value } = ratings || EMPTY_OBJECT;

  return <div className='font-medium'>{value}</div>;
};

const RenderSelectedPolicyDetails = ({ policyValue, policyName }) => {
  switch (policyName) {
    case COST:
      return <CostPolicyData policyValue={policyValue} />;
    case AIRLINES:
      return <AirlinesNameBadges airlinesNames={policyValue.data} />;
    case RATING:
      return <HotelRatings ratings={policyValue} />;
  }
};

const PolicyDetailBody = ({ policyValue, policyName }) => {
  const currentUserInfo = useSelector(selectCurrentUserInfo);

  const [shouldOpenEditModal, setShouldOpenEditModal] = useState();
  const [selectedEditablePolicy, setSelectedEditablePolicy] = useState({
    policyName: null,
    policyType: null,
  });

  const handleOnEditPolicy = (policyName, policyType) => {
    setShouldOpenEditModal(true);
    setSelectedEditablePolicy({ policyName, policyType });
  };
  const handleClose = () => setShouldOpenEditModal(null);
  const { canEditPolicies } = getRequiredPermissions(currentUserInfo);

  return (
    <>
      {Object.keys(policyValue).map((key) => (
        <tr className='w-full' key={key}>
          <td className='w-[40%]  px-4 py-4 border  border-slate-200 capitalize'>
            <div className='flex flex-col gap-1'>
              <span className='font-medium'>{`${key} ${policyName}`}</span>
              <span
                className={classNames(
                  "text-xs text-white w-fit px-3 py-1 rounded-full",
                  {
                    "bg-primary-600": policyValue[key].isEnabled,
                    "bg-gray-600": !policyValue[key].isEnabled,
                  }
                )}
              >
                {policyValue[key].isEnabled ? "Enabled" : "Disabled"}
              </span>
            </div>
          </td>
          <td className='px-4 py-4 border text-gray-900 border-slate-200'>
            <div className='flex items-center justify-between'>
              <div
                className='font-medium text-sm h-20 overflow-y-auto no-scrollbar flex items-center'
                key={key}
              >
                <RenderSelectedPolicyDetails
                  policyValue={policyValue[key]}
                  policyName={policyName}
                />
              </div>
              {canEditPolicies && (
                <div
                  className='hover:cursor-pointer'
                  onClick={() => handleOnEditPolicy(policyName, key)}
                >
                  <RenderSVG Svg={EditFill} />
                </div>
              )}
            </div>
          </td>
        </tr>
      ))}

      {shouldOpenEditModal && (
        <ManagePolicyModal
          selectedServicePolicyData={selectedEditablePolicy}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default PolicyDetailBody;
