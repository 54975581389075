import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import Badge from "../../components/atoms/Badge";
import {
  NOT_AVAILABLE,
  SEARCH_SECTION,
  CACHE_KEYS,
  ANALYTICS_PARAM,
} from "../../constants";
import { Table } from "../../components/molecules";
import { setSelectedTripId } from "../Booking/Trips";
import { setToSessionStorage } from "../../helper";
import { NoTripData } from "../../components/organisms/AppCharts";
import { SetUpYourCompany, Users } from "../../assets/icons";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  CalendarViewSkeleton,
  UserUpcomingBookingsSkeleton,
  TenantUpcomingBookingsSkeleton,
} from "../../components/organisms/AppSkeletons/DashboardSkeleton";
import CalenderViewBookings from "../../components/molecules/CalenderViewBookings";
import { selectCurrentUserInfo } from "../Auth";
import { getUserBookingsInfo } from "./dashboard.actions";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { DEFAULT_VALUES } from "../../constants";
import { FlightIcon, RenderSVG, HotelsDarkIcon } from "../../assets/icons";

const { ZERO, EMPTY_STRING } = DEFAULT_VALUES;
const { FLIGHT } = SEARCH_SECTION;
const { BOOKING_ID } = CACHE_KEYS;
const { ANALYTICS_USER_UPCOMING_BOOKINGS, FETCH_MONTH_BASED_BOOKINGS } =
  SPINNER_NAMES;
const {
  INTERVAL: { CUSTOM, MONTH, YEAR, QUARTER },
  REFERENCE_PERIOD: { CURRENT, LAST },
} = ANALYTICS_PARAM;

const StatusFormatter = ({ row }) => {
  const { status } = row;
  return status ? <Badge value={status} /> : NOT_AVAILABLE;
};

const DateFormatter = ({ row }) => {
  const { date } = row;
  if (date) {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString('en-CA'); 
    return formattedDate;
  }
  return "NOT AVAILABLE";
};

const EventNameFormatter = ({ row }) => {
  const { type, origin, destination, hotelName } = row;
  const eventName = type === FLIGHT ? `${origin} to ${destination}` : hotelName;
  return (
    <div className='relative group'>
      <div className='font-semibold truncate w-32'>
        {eventName}
      </div>
      <div className='absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded-lg p-2 shadow-lg transition-opacity duration-300 opacity-0 group-hover:opacity-100 -mt-8 left-1/2 transform -translate-x-1/2'>
        {eventName}
      </div>
    </div>
  );
};

const BookingIdFormatter = ({ row }) => {
  const { tavaBookingId } = row;

  return (
    <div className='relative group'>
      <div className='font-semibold text-blue-700 truncate w-32'>
        {tavaBookingId}
      </div>
      <div className='absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded-lg p-2 shadow-lg transition-opacity duration-300 opacity-0 group-hover:opacity-100 -mt-8 left-1/2 transform -translate-x-1/2'>
        {tavaBookingId}
      </div>
    </div>
  );
};

const BookingTypeFormatter = ({ row }) => {
  const { type } = row;
  return <div className='icon w-6 h-6 grid place-content-center rounded-full bg-blue-100'>
    <RenderSVG
      Svg={type === 'flight' ? FlightIcon : HotelsDarkIcon}
      alt={"Booking "}
      className='text-primary-600'
    />
  </div>
}

const SelectedUsersHeader = ({ users, selectedUserId, setSelectedUserId }) => {
  return (
    <div className='border-b border-contrast-300 w-full flex items-center m-auto p-3 overflow-x-auto'>
      {users &&
        users.map(({ userId }) => {
          const { name, profilePic } =
            users.find((user) => user.userId === userId) || {};

          return (
            <div key={userId}>
              {name && (
                <>
                  <Tooltip
                    id={userId}
                    className='!text-xs !rounded-lg max-w-xs'
                  />
                  <div
                    data-tooltip-id={userId}
                    data-tooltip-place='top'
                    data-tooltip-content={name}
                    className='flex-shrink-0 ml-3 m-auto'
                    onClick={() => setSelectedUserId(userId)}
                  >
                    <img
                      className={classNames(
                        "rounded-full cursor-pointer m-auto ",
                        {
                          "shadow-lg border-4 border-primary-400 shadow-primary-300 opacity-100 w-10 h-10":
                            selectedUserId === userId,
                          "opacity-40 w-8 h-8 border-2 border-primary-800":
                            selectedUserId !== userId,
                        }
                      )}
                      src={
                        profilePic
                          ? profilePic
                          : `https://robohash.org/${name}.png?size=500x500&&bgset=bg2`
                      }
                      alt={name}
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

const TenantUpcomingBookings = ({
  userUpcomingBookings: tenantUpcomingBookings = {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tenantBookingsData, setTenantBookingsData] = useState(null);

  const columns = [
    {
      text: EMPTY_STRING,
      formatter: <BookingIdFormatter />,
    },
    {
      text: EMPTY_STRING,
      formatter: <BookingTypeFormatter />,
    },
    {
      text: EMPTY_STRING,
      formatter: <EventNameFormatter />,
    },
    {
      text: EMPTY_STRING,
      formatter: <DateFormatter />,
    },
    {
      text: EMPTY_STRING,
      formatter: <StatusFormatter />,
    },
  ];

  useEffect(() => {
    if (tenantUpcomingBookings)
      setTenantBookingsData(tenantUpcomingBookings);
    else setTenantBookingsData(null);
  }, []);

  const handleNavigateToBooking = (row) => {
    const { tavaBookingId } = row;
    const BOOKING = `/bookings/${tavaBookingId}`;
    navigate(BOOKING);
    dispatch(setSelectedTripId(tavaBookingId));
    setToSessionStorage(BOOKING_ID, tavaBookingId);
  };

  return (
    <div className='w-full h-full border border-contrast-300 rounded-lg bg-white'>
      <div className='p-6 text-sm font-medium font-inter text-base text-[#111827]  gap-2'>
        Tenant Confirmed Upcoming Bookings
      </div>
      {
        tenantBookingsData && Object.entries(tenantBookingsData).length > 0 ? (
          <div className="px-4 max-h-96 overflow-y-auto">
            {Object.entries(tenantBookingsData).map(([userId, bookings]) => (
              <Table
                key={userId}
                columnDefs={columns}
                rowData={bookings}
                showTableHeader={false}
                handleNavigtation={handleNavigateToBooking}
              />
            ))}
          </div>
        )
          : (
            <div className='p-2'>
              <NoTripData
                title='No Future Confirmed Bookings'
                description="No upcoming bookings available for the Tenant."
                imgName={SetUpYourCompany}
              />
            </div>
          )}
    </div>
  );
};

const UserUpcomingBookings = ({
  userUpcomingBookings = {},
  finalSelectedUser,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedUserId, setSelectedUserId] = useState(
    finalSelectedUser[0].userId
  );
  const [userBookingsData, setUserBookingsData] = useState(null);

  const columns = [
    {
      text: EMPTY_STRING,
      formatter: <BookingTypeFormatter />,
    },
    {
      text: "",
      formatter: <EventNameFormatter />,
    },
    {
      text: "",
      formatter: <DateFormatter />,
    },
    {
      text: "",
      formatter: <StatusFormatter />,
    },
  ];

  useEffect(() => {
    if (userUpcomingBookings.hasOwnProperty(selectedUserId))
      setUserBookingsData(userUpcomingBookings[selectedUserId]);
    else setUserBookingsData(null);
  }, [selectedUserId]);

  const handleNavigateToBooking = (row) => {
    const { tavaBookingId } = row;
    const BOOKING = `/bookings/${tavaBookingId}`;
    navigate(BOOKING);
    dispatch(setSelectedTripId(tavaBookingId));
    setToSessionStorage(BOOKING_ID, tavaBookingId);
  };

  return (
    <div className='w-full h-full border border-contrast-300 rounded-lg bg-white'>
      <div className='p-3 text-sm font-medium font-inter text-[14px] text-[#111827] gap-2'>
        User Confirmed Bookings
      </div>
      <SelectedUsersHeader
        users={finalSelectedUser}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
      />
      {userBookingsData ? (
        <Table
          columnDefs={columns}
          rowData={userBookingsData}
          showTableHeader={false}
          handleNavigtation={handleNavigateToBooking}
        />
      ) : (
        <div className='p-2'>
          <NoTripData
            title='No Future Confirmed Bookings'
            description={
              "No upcoming bookings available for the selected user."
            }
            imgName={SetUpYourCompany}
          />
        </div>
      )}
    </div>
  );
};

const MonthBasedUserBookings = ({
  finalSelectedUser = [],
  currentUserInfo,
  currentUserBookings,
  setCurrentUserBookings,
}) => {
  const [selectedUserId, setSelectedUserId] = useState(
    finalSelectedUser[0].userId
  );
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [userBookingsData, setUserBookingsData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (finalSelectedUser.length > ZERO) {
      const isValidUser = finalSelectedUser.some(user => user.userId === selectedUserId);
      if (!isValidUser) {
        setSelectedUserId(finalSelectedUser[ZERO].userId);
      }
    }
  }, [finalSelectedUser, selectedUserId]);

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);

    const startOfMonth = moment(activeStartDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(activeStartDate)
      .endOf("month")
      .format("YYYY-MM-DD");
    const userIds = finalSelectedUser.map((u) => u.userId);
    const shouldPassUserId = !(userIds.length === 1 && userIds[0] === "");
    dispatch(
      getUserBookingsInfo({
        interval: CUSTOM,
        startDate: startOfMonth,
        endDate: endOfMonth,
        userId: shouldPassUserId ? userIds : currentUserInfo.id,
      })
    ).then((res) => {
      setCurrentUserBookings(res?.payload?.data);
    });
  };

  useEffect(() => {
    if (
      currentUserBookings &&
      currentUserBookings.hasOwnProperty(selectedUserId)
    )
      setUserBookingsData(currentUserBookings[selectedUserId]);
    else setUserBookingsData(null);
  }, [selectedUserId, currentUserBookings]);

  return (
    <div className='border border-contrast-300 rounded-lg gap-7 bg-white overflow-x-auto min-w-[33%]'>
      <div className='p-3 text-sm font-medium font-inter text-[14px] text-[#111827] gap-2'>
        User Month Based Bookings
      </div>
      <SelectedUsersHeader
        users={finalSelectedUser}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
      />
      <div className='min-h-96 !min-w-1/3 !mx-6 !my-2 flex justify-center items-center overflow-x-auto border-2 rounded-md'>
        <Spinner
          name={FETCH_MONTH_BASED_BOOKINGS}
          showSkeleton={true}
          loaderComponent={<CalendarViewSkeleton />}
          className='inline-block w-full'
        >
          <CalenderViewBookings
            bookings={userBookingsData}
            onActiveStartDateChange={handleActiveStartDateChange}
            activeStartDate={activeStartDate}
          />
        </Spinner>
      </div>
    </div>
  );
};

const UpcomingAndMonthlyBookings = ({
  finalSelectedUser,
  userUpcomingBookings,
  currentUserBookings,
  setCurrentUserBookings,
}) => {
  const currentLoggedUser = useSelector(selectCurrentUserInfo);
  const selectedUsers = finalSelectedUser.filter(
    (user) => user.name !== "Innostax"
  );

  const formattedUsersList = [
    ...(selectedUsers.length
      ? [...selectedUsers]
      : [
          {
            name: currentLoggedUser.name,
            profilePic: currentLoggedUser.profilePic,
            userId: currentLoggedUser.id,
          },
        ]),
    ,
  ];

  return (
    <div className='px-2  w-full gap-2 flex overflow-hidden'>
      {finalSelectedUser[ZERO].userId ?
        (<div className="mx-4 w-full gap-2 flex  flex-col lg:flex-row overflow-hidden">
          <div className='bg-white w-full lg:w-2/3 overflow-x-auto'>
            <Spinner
              size='w-full'
              name={ANALYTICS_USER_UPCOMING_BOOKINGS}
              showSkeleton={true}
              loaderComponent={<UserUpcomingBookingsSkeleton />}
              className='inline-block'
            >
              <UserUpcomingBookings
                finalSelectedUser={formattedUsersList}
                userUpcomingBookings={userUpcomingBookings}
              />
            </Spinner>
          </div>
          <div className='w-full lg:w-1/3'>
            {finalSelectedUser[ZERO].userId &&
              <MonthBasedUserBookings
                currentUserInfo={currentLoggedUser}
                finalSelectedUser={formattedUsersList}
                currentUserBookings={currentUserBookings}
                setCurrentUserBookings={setCurrentUserBookings}
              />
            }
          </div></div>
        ) : (<div className='px-4 w-full flex flex-col overflow-hidden' >
          <div className='bg-white w-full rounded-lg shadow-md overflow-hidden' >
            <Spinner
              size='w-full'
              name={ANALYTICS_USER_UPCOMING_BOOKINGS}
              showSkeleton={true}
              loaderComponent={<TenantUpcomingBookingsSkeleton />}
              className='inline-block'
            >
              <TenantUpcomingBookings
                userUpcomingBookings={userUpcomingBookings}
              />
            </Spinner>
          </div>
        </div>)}
    </div>
  );
};

export default UpcomingAndMonthlyBookings;
