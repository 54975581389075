import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../../infrastructure/asyncAction";
import { DEFAULT_VALUES, REQUEST_METHODS } from "../../../constants";
import { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import { createQueryString } from "../../../helper";

const { GET, POST, DELETE, PATCH } = REQUEST_METHODS;
const { EMPTY_STRING } = DEFAULT_VALUES;
const {
  FETCH_TRIPS,
  CREATE_TRIP,
  PAYMENT_PROVIDER,
  PROCESS_PAYMENT,
  BOOK_CALLBACK,
  DOWNLOAD_TICKET,
  EMAIL_ETICKET,
  DELETE_TRIP_BY_ID,
  BOOKING_PAYMENT_TERMINATE,
  DELETE_CHILD_BOOKING_BY_PARENT_ID,
  UPDATE_HOTEL_VOUCHERED_STATUS,
} = SPINNER_NAMES;

const createBookingParams = (params) => (params ? `?${params}` : EMPTY_STRING);

export const fetchTrips = createAsyncThunk(
  "fetch-trips",
  async (params, thunkArgs) => {
    const queryParams = createBookingParams(params);
    return await asyncAction({
      url: `bookings${queryParams}`,
      methodType: GET,
      isAuthRequired: true,
      spinner: FETCH_TRIPS,
      errorMessage: "Failed to fetch trips",
      ...thunkArgs,
    });
  }
);

export const fetchTripsById = createAsyncThunk(
  "get-booking",
  async (id, thunkArgs) => {
    return await asyncAction({
      url: `bookings/${id}`,
      methodType: GET,
      isAuthRequired: true,
      spinner: FETCH_TRIPS,
      errorMessage: "Failed to fetch trips",
      ...thunkArgs,
    });
  }
);

export const initiatePayment = createAsyncThunk(
  "initiate-payment",
  async (requestBody, thunkArgs) => {
    return await asyncAction({
      url: `payment`,
      methodType: POST,
      body: JSON.stringify(requestBody),
      showErrorToast: true,
      isAuthRequired: true,
      abortOnPageChange: false,
      spinner: PROCESS_PAYMENT,
      errorMessage: "Checkout payment failed.",
      ...thunkArgs,
    });
  }
);

export const fetchPaymentProviders = createAsyncThunk(
  "fetch-payment-providers",
  async (_, thunkArgs) => {
    return await asyncAction({
      url: `payment-providers`,
      methodType: GET,
      showErrorToast: true,
      isAuthRequired: true,
      spinner: PAYMENT_PROVIDER,
      errorMessage: "Failed to load payment providers.",
      ...thunkArgs,
    });
  }
);

export const updatePaymentStatus = createAsyncThunk(
  "update-payment-status",
  async ({bookingId}, thunkArgs) => {
    return await asyncAction({
      url: `bookings/${bookingId}/terminate`,
      methodType: GET,
      showErrorToast: true,
      isAuthRequired: true,
      spinner: BOOKING_PAYMENT_TERMINATE,
      abortOnPageChange: false,
      errorMessage: "Failed to update payment status",
      ...thunkArgs,
    });
  }
);

export const initiateBookCallback = createAsyncThunk(
  "initiate-book-callback",
  async ({ requestBody, url }, thunkArgs) => {
    return await asyncAction({
      url,
      methodType: POST,
      showErrorToast: true,
      body: JSON.stringify(requestBody),
      isAuthRequired: true,
      abortOnPageChange: false,
      spinner: BOOK_CALLBACK,
      errorMessage: "Checkout payment failed.",
      ...thunkArgs,
    });
  }
);

export const createTrip = createAsyncThunk(
  "create-trip",
  async (requestBody, thunkArgs) => {
    return await asyncAction({
      url: `create-trip`,
      methodType: POST,
      body: JSON.stringify(requestBody),
      showErrorToast: true,
      isAuthRequired: true,
      spinner: CREATE_TRIP,
      errorMessage: "Failed to create trip",
      ...thunkArgs,
    });
  }
);

export const downloadEticket = createAsyncThunk(
  "download-trip",
  async ({ params, tavaBookingId }, thunkArgs) => {
    const queryParams = createQueryString(params);
    return await asyncAction({
      url: `bookings/${tavaBookingId}/downloadETicket${queryParams}`,
      methodType: GET,
      showErrorToast: true,
      isAuthRequired: true,
      spinner: `${DOWNLOAD_TICKET}_${tavaBookingId}`,
      errorMessage: "Failed to get ticket",
      ...thunkArgs,
    });
  }
);

export const sendETicketEmail = createAsyncThunk(
  "send-eticket-email",
  async ({params, tavaBookingId}, thunkArgs) => {
    const queryParams = createQueryString(params);
    return await asyncAction({
      url: `/bookings/${tavaBookingId}/sendEticketEmail${queryParams}`,
      methodType: GET,
      showErrorToast: true,
      isAuthRequired: true,
      spinner: EMAIL_ETICKET,
      errorMessage: "Failed to send Eticket Email!",
      ...thunkArgs,
    });
  }
);

export const deleteBookingById = createAsyncThunk(
  "delete-booking-by-id",
  async (id, thunkArgs) => {
    return await asyncAction({
      url: `/bookings/${id}`,
      methodType: DELETE,
      showErrorToast: true,
      isAuthRequired: true,
      spinner: DELETE_TRIP_BY_ID,
      errorMessage: "Failed to delete Trip",
      ...thunkArgs,
    });
  }
);

export const deleteChildByBookingId = createAsyncThunk(
  "delete-child-by-parent-booking-id",
  async ({ id, type }, thunkArgs) => {
    return await asyncAction({
      url: `/bookings/${id}/${type}`,
      methodType: PATCH,
      showErrorToast: true,
      isAuthRequired: true,
      spinner: DELETE_CHILD_BOOKING_BY_PARENT_ID,
      errorMessage: `Failed to delete ${type} booking from the trip`,
      ...thunkArgs,
    });
  }
);

export const updateHotelVoucherStatus = createAsyncThunk(
  "patch-hotel-voucher-status-udpate",
  async ({ id, requestBody }, thunkArgs) => {
    return await asyncAction({
      url: `/hotels/voucher-status/${id}`,
      methodType: PATCH,
      body: JSON.stringify(requestBody),
      showErrorToast: true,
      isAuthRequired: true,
      spinner: UPDATE_HOTEL_VOUCHERED_STATUS,
      errorMessage: `Failed to updated hotel voucher status`,
      ...thunkArgs,
    });
  }
);

