import { isEmpty, debounce } from "lodash";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderSVG, UserCircle } from "../../../assets/icons";
import { getUsersList } from "../../../screens/Users/users.actions";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import { DRAWERS } from "../../organisms/AppDrawers/drawer.constants";
import { setSelectedDrawer } from "../../organisms/Drawer";
import { getFromLocalStorage } from "../../../helper";
import { CACHE_KEYS, DEFAULT_VALUES, REGEX } from "../../../constants";
import { setSelectedTripDetail, setSelectedUserInfo } from "../../../screens/Booking/Trips";
import { selectUserInfo } from "../../../screens/Profile";
import { selectTripDetail } from "../../../screens/Booking/Trips/trips.selector";
import { getTravelersAgeFromDOB } from "../../../helper";

const { SHOW_ADD_USER_DRAWER, SHOW_SELECTED_USER_INFO_DRAWER } = DRAWERS;
const { AUTH } = CACHE_KEYS;
const { EMPTY_STRING, NINE, TEN, ZERO ,SIXTY } = DEFAULT_VALUES;
const { FETCH_USERS } = SPINNER_NAMES;
const {WHITESPACES_AT_END , WHITE_SPACES_DETECTION_REGEX , CHARACTER_AFTER_WHITESPACE}= REGEX;
const EIGHTEEN =18;

const TravelerSelector = ({ setFieldValue, name, setFieldTouched, values, selected, setSelected }) => {
  const userInfo = useSelector(selectUserInfo);
  const [query, setQuery] = useState(EMPTY_STRING);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(ZERO);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [isMinor, setIsMinor] = useState({}); 
  const optionsRef = useRef(null);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState(EMPTY_STRING);
  const { id } = getFromLocalStorage(AUTH);
  const isTravellerLimitExceeded = values.travelers?.length > SIXTY;
  const filteredResults = useMemo(() => results?.filter((item) => !selected.find((sel) => sel.id === item.id)), [results, selected])
  const newTripDetail = useSelector(selectTripDetail);
  const newTraveler = newTripDetail.newTraveler;
  const noRecordsMessage = `No Results found ${inputValue ? `for "${inputValue}" ` : ""}`;
  const isFetchingRef = useRef(false);
  const handleSelect = (item) => {
    // Added this check to avoid selecting a user twice when the ID is already preselected in Redux
    dispatch(setSelectedTripDetail({...newTripDetail, newTraveler : {}}));
    !selected.some((selectedUser) => selectedUser.id === item.id) &&
      setSelected((prevSelected) => [...prevSelected, item]);
    setQuery("");
    setInputValue(EMPTY_STRING);
    setHasMore(true);
    setDropdownOpen(false);
    setFieldTouched(name, true);
  };

  const handleRemove = (id) => {
    setSelected(selected.filter((item) => item.id !== id));
    dispatch(setSelectedTripDetail({...newTripDetail, newTraveler : {}}));
  };

  const fetchUsers = useCallback(() => {
    if (!hasMore || isFetchingRef.current) return;
    isFetchingRef.current = true;
    const nextPage=page+1;
    setIsFetching(true);
    setPage(nextPage);
    dispatch(
      getUsersList({
        pageNumber: nextPage,
        pageSize: 10,
        searchKey: query,
        action: "USERSEARCH",
      })
    ).then((response) => {
      isFetchingRef.current = false;
      if(!response.payload) return setHasMore(false)
      const { data: fetchedUsers = [], countInfo = {} } = response?.payload || {}
      const minorStatus = {};
      
      fetchedUsers.forEach((user) => {
        const dateOfBirth = user.dateOfBirth;
        if (user.type === "TRAVELER" && dateOfBirth) {
          const age = getTravelersAgeFromDOB(dateOfBirth);
          if (
            typeof age === "object" ||
            (typeof age === "number" && age < EIGHTEEN)
          ) {
            minorStatus[user.id] = true;
          }
        } else {
          minorStatus[user.id] = false;
        }
      });
      setIsMinor(minorStatus);

      // We have used this check to set setHasMore to true if the number of users exceeds 10, as we display 10 results per page with lazy loading
      if (+countInfo.currentPage >= +countInfo.totalPage) {
        setHasMore(false);
      }
      if (fetchedUsers) {
        setResults((prevResults) => [...prevResults, ...fetchedUsers]);
      }
      setIsFetching(false);
      setSearchTriggered(false);
    });
  }, [dispatch, page, query, hasMore, isFetching, results]);

  const debouncedSetQuery = useCallback(
    debounce((value) => setQuery(value), 1000),
    []
  );

const shouldDebounceQuery = (trimmedValue) => {
  return !WHITESPACES_AT_END.test(trimmedValue) || 
         (WHITE_SPACES_DETECTION_REGEX.test(trimmedValue) && CHARACTER_AFTER_WHITESPACE.test(trimmedValue));
}

  const handleOnChange = (event) => {
    const value = event.target.value;
    const trimmedValue = value?.trim();

    setInputValue(value); 

    if (!trimmedValue || trimmedValue.length === 0) {
        setFieldTouched(name, true);
        return;
    }

    if (shouldDebounceQuery(trimmedValue)) {
        debouncedSetQuery(trimmedValue);
    }

    setFieldTouched(name, true);
};

  useEffect(() => {
    userInfo &&
      setSelected((prevSelected) => [
        userInfo,
        ...prevSelected.filter(
          (selectedUser) => selectedUser.id !== userInfo.id
        ),
      ]);
  }, [userInfo]);

  useEffect(() => {
    setResults([])
    fetchUsers();
    setSearchTriggered(true)
  }, [query]);

  useEffect(() => {
    if(!isEmpty(newTraveler)){
      setSelected((prevSelected) => [...prevSelected, newTraveler]);
      dispatch(setSelectedTripDetail({...newTripDetail, newTraveler : {}}));
    }
    setFieldValue(name, selected);
  }, [selected, setFieldValue, name, newTripDetail]);

  const handleClickOutside = useCallback((event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      // setInputValue(EMPTY_STRING);
      setDropdownOpen(false);

      if (!isEmpty(query) || isEmpty(filteredResults)) {
        setHasMore(true);
        setQuery(EMPTY_STRING);
        setPage(ZERO)
      }
    } else {
      setDropdownOpen(true);
    }
  }, [query, filteredResults]);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen, handleClickOutside]);


  const registerNewTraveler = useCallback(() => {
    dispatch(setSelectedDrawer(SHOW_ADD_USER_DRAWER));
  }, [dispatch]);

  const handleScroll = useCallback(() => {
    if (optionsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = optionsRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 10) {
        fetchUsers();
      }
    }
  }, [fetchUsers]);

  useEffect(() => {
    if (dropdownOpen && optionsRef.current && !isFetching) {
      optionsRef.current.addEventListener("scroll", handleScroll);
      optionsRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
      return () => {
      if (optionsRef.current) {
        optionsRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [dropdownOpen, isFetching]);

  const getInitials = (firstName, lastName, name) => {
    if (firstName && lastName) {
      const firstInitial = firstName[0].toUpperCase();
      const lastInitial = lastName[0].toUpperCase();
      return `${firstInitial}${lastInitial}`;
    }

    if (name) {
      const [firstPart, secondPart] = name.split(" ");
      return `${(firstPart || "")[0].toUpperCase()}${
        secondPart ? secondPart[0].toUpperCase() : ""
      }`;
    }

    return "UN";
  };

  return (
    <div className='w-full relative' ref={dropdownRef}>
      <div className='items-center border border-gray-300 flex flex-wrap max-h-[120px] sm:max-h-[130px] overflow-y-auto gap-2'>
        <div className='items-start p-2 rounded flex flex-col flex-wrap w-full gap-2'>
          <div className="flex flex-col xs:flex-row gap-2">
            <div className='flex w-full relative'>
              <div className='relative flex-wrap'>
                <input
                  type='text'
                  disabled={isTravellerLimitExceeded}
                  onClick={() => setDropdownOpen(true)}
                  onChange={(e) => {
                    setHasMore(true);
                    handleOnChange(e);
                    setDropdownOpen(true);
                    setPage(ZERO);
                  }}
                  placeholder={` Search ${!selected?.length ? "first traveler" : "traveler"
                    }`}
                  className={`border pl-8 !pt-2 !pb-2 border-gray-300 max-w-44 h-8 rounded-md col-span-${5 - selected.length
                    } focus:outline-none focus:ring-0`}
                  value={inputValue}
                />
                <RenderSVG
                  Svg={UserCircle}
                  className='absolute top-1/2 transform -translate-y-1/2 w-5 h-5 rounded-full text-primary-600 ml-2'
                />
              </div>
            </div>
            <div className='flex items-center'>
              <span className='text-left pr-2'>OR</span>
              <button
                className='text-white items-center font-medium disabled:cursor-not-allowed disabled:bg-primary-400 h-8 px-3 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm whitespace-nowrap'
                onClick={registerNewTraveler}
              >
                Add Traveler
              </button>
            </div>
          </div>
          <div className="flex flex-wrap gap-2">
            {selected.map((item) => (
              <div
                key={item.id}
                className='flex items-center bg-gray-100 p-[5px] rounded-md hover:border-primary-300 border'
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setSelectedUserInfo(item));
                  dispatch(setSelectedDrawer(SHOW_SELECTED_USER_INFO_DRAWER));
                }}
              >
                {selected.length && (
                  <RenderSVG
                    Svg={UserCircle}
                    className='w-5 h-5 rounded-full mr-2 text-primary-600'
                  />
                )}
                <span className='text-sm truncate max-w-[100px]'>
                  {item.id === userInfo?.id
                    ? `${userInfo?.firstName || "Unnamed"} ${userInfo?.middleName ? userInfo?.middleName + " " : ""
                    }${userInfo?.lastName || ""} (You)`
                    : `${item.firstName || "Unnamed"} ${item.middleName ? item.middleName + " " : ""
                    }${item.lastName || ""}${item.id === id ? " (You)" : ""}`}
                </span>
                <button
                  onClick={(e) => {
                    handleRemove(item.id);
                    e.stopPropagation();
                  }}
                  className='ml-auto bg-transparent px-1 border-none cursor-pointer text-sm font-bold text-primary-600 hover:text-primary-800'
                >
                  x
                </button>
              </div>
            ))}
          </div>
        </div>

        {dropdownOpen && (
          <div className="w-full">
            <div
              ref={optionsRef}
              className='flex border absolute top-10 z-[1000] left-0 border-gray-300 mt-1 w-[95%] sm:w-2/3 overflow-y-auto bg-white max-h-40 transition-all duration-300 ease-in-out transform origin-top scale-y-100 opacity-100 rounded-md shadow-md focus:border-primary-300'
            >
              <ul className='w-full'>
                {!isEmpty(results) ? filteredResults
                  .map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelect(item)}
                      className='p-2 flex items-center cursor-pointer hover:bg-gray-100 w-full hover:text-primary-400'
                    >
                      {item.profilePic ? (
                        <img
                          src={item.profilePic}
                          className='h-10 w-10 rounded-full mr-2'
                        />
                      ) : (
                        <div className='flex items-center justify-center w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-primary-500 text-white mr-2'>
                          {getInitials(item.firstName, item.lastName, item.name)}
                        </div>
                      )}
                      <div className='flex flex-col mr-14'>
                        <span>
                          {item.name
                            ? item.name
                            : `${item.firstName || "Unnamed"} ${item.middleName ? item.middleName + " " : ""
                            }${item.lastName || ""}`}
                        {item.id === id && <span> (You)</span>}
                      </span>
                      <span className='text-gray-500'>{item.email}</span>
                    </div>
                      {isMinor[item.id] && (
                        <div className='bg-primary-500 bg-contrast-100 flex items-center px-3 py-1 rounded-2xl'>
                          <span className="text-white text-xs"> Minor</span>
                        </div>
                      )}
                    </li>
                ))  : ((!searchTriggered && !isFetching) &&
                    <div className="flex items-center ml-4 p-1">
                      <h2>{noRecordsMessage}</h2>
                    </div>
                )}
                <li className='p-2 flex items-center justify-center'>
                  <Spinner name={FETCH_USERS} />
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TravelerSelector;
