import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderSVG, CurrencyDollar, Plus } from "../../../assets/icons";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import { DRAWERS } from "../../organisms/AppDrawers/drawer.constants";
import { setSelectedDrawer } from "../../organisms/Drawer";
import { fetchCostCodes } from "../../../screens/CostCodes/cost.actions";
import { selectCurrentUserInfo } from "../../../screens/Auth";
import { debounce, isEmpty } from "lodash";
import { DEFAULT_VALUES, AUTH_ROLES, REGEX } from "../../../constants";
import { selectUserInfo } from "../../../screens/Profile";
import { selectTripDetail } from "../../../screens/Booking/Trips/trips.selector";
import { setSelectedTripDetail } from "../../../screens/Booking/Trips";
import { useFormikContext } from "formik";

const { SHOW_ADD_COSTCODE_DRAWER } = DRAWERS;
const { EMPTY_STRING, TEN, ONE, ZERO, SINGLE_SPACE_STRING } = DEFAULT_VALUES;
const { COST_CODES } = SPINNER_NAMES;
const { CONSECUTIVE_WHITESPACE_REGEX } = REGEX;

const AddCostCodeSelector = ({
  setFieldValue,
  name,
  setSelectedCostCodeID,
}) => {
  const [query, setQuery] = useState(EMPTY_STRING);
  const [results, setResults] = useState([]);
  const [selected, setSelected] = useState(null);
  const newTripDetail = useSelector(selectTripDetail);
  const newCostCode = newTripDetail.costCode;
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState(EMPTY_STRING);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const optionsRef = useRef(null);
  const dropdownRef = useRef(null);
  const isResultSelected = useRef(false);
  const pageRef = useRef(1);
  const dispatch = useDispatch();
  const { tenantId } = useSelector(selectCurrentUserInfo);
  const COSTCODESEARCH = "COSTCODESEARCH";
  const userInfo = useSelector(selectUserInfo);
  const {role = ""} = userInfo || {};
  const [lastKeySearch, setLastKeySearch] = useState(EMPTY_STRING);
  const isFetchingRef = useRef(false);
  const { handleChange } = useFormikContext()

  const handleSelect = (item) => {
    setSelectedCostCodeID(item.id);
    setInputValue(EMPTY_STRING);
    setQuery(EMPTY_STRING);
    setHasMore(true);
    setDropdownOpen(false);
    setFieldValue(name, item);
    dispatch(setSelectedTripDetail({...newTripDetail, costCode:{}}));
    if (selected && selected.id === item.id) setSelected(null);
    else setSelected(item);
  };

  const handleRemove = (e) => {
    handleChange(e);
    setSelected(null);
    setSelectedCostCodeID(null);
    dispatch(setSelectedTripDetail({...newTripDetail, costCode:{}}));
  };

  const fetchCostCodesData = useCallback(() => {
    if (!hasMore || isFetchingRef.current) return;
    isFetchingRef.current = true;
    setIsFetching(true);
    const currentPage = pageRef.current;
    dispatch(
      fetchCostCodes({
        pageNumber: currentPage,
        pageSize: 10,
        searchKey: query,
        tenantId,
        action: COSTCODESEARCH,
      })
    ).then((response) => {
      isFetchingRef.current = false;
      const fetchedCostCodes = response?.payload?.result || [];
      const totalPage = parseInt(response?.payload?.countInfo?.totalPage, TEN) || ONE;
      if (totalPage <= currentPage) setHasMore(false);
      if(fetchedCostCodes) setResults((prevResults) => [...prevResults, ...fetchedCostCodes]);
      pageRef.current = currentPage + 1;
      setIsFetching(false);
      setSearchTriggered(false);
    });
  }, [dispatch, query, hasMore, isFetching, tenantId]);

  const debouncedSetQuery = useCallback(
    debounce((value) => {
      setQuery(value);
    }, 1000),
    []
  );

  const handleOnChange = (event) => {
    const value = event.target.value;
    const trimmedValue=value.trim().replace(CONSECUTIVE_WHITESPACE_REGEX, SINGLE_SPACE_STRING);
    setInputValue(value);
    if (!value || (trimmedValue && lastKeySearch !== trimmedValue)) {
      debouncedSetQuery(value);
      setHasMore(true);
      pageRef.current = 1;
      setLastKeySearch(trimmedValue);
    }
  };

  useEffect(() => {
    if (!isEmpty(results) && !isResultSelected.current) {
      setSelected(results[ZERO]);
      setSelectedCostCodeID(results[ZERO].id);
      setFieldValue(name, results[ZERO]);
      isResultSelected.current = true;
    }
  }, [results])

  useEffect(() => {
    fetchCostCodesData();
    setResults([])
    setSearchTriggered(true);
  }, [query]);

  useEffect(() => {
    if(!isEmpty(newCostCode)){
      setSelected(newCostCode);
      setSelectedCostCodeID(newCostCode.id);
    }
    setFieldValue(name, selected);
  }, [selected, setFieldValue, name, newCostCode]);

  const handleClickOutside = useCallback((event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
     // setInputValue(EMPTY_STRING);
      setDropdownOpen(false);

      if (!isEmpty(query) || isEmpty(results)) {
        setHasMore(true);
        pageRef.current = 1;
        setQuery(EMPTY_STRING);
        fetchCostCodesData();
      }
    } else {
      setDropdownOpen(true);
    }
  }, [query, results]);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen, handleClickOutside]);


  const registerNewCostCode = useCallback(() => {
    dispatch(setSelectedDrawer(SHOW_ADD_COSTCODE_DRAWER));
  }, [dispatch]);

  const handleScroll = () => {
    if (optionsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = optionsRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 10) fetchCostCodesData();
    }
  };

  useEffect(() => {
    if (dropdownOpen && optionsRef.current) {
      optionsRef.current.addEventListener("scroll", handleScroll);
      optionsRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
    return () => {
      if (optionsRef.current)
        optionsRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [dropdownOpen, fetchCostCodesData]);

  return (
    <div className='w-full relative' ref={dropdownRef}>
      <div className='items-start sm:items-center border border-gray-300 p-2 rounded flex flex-col sm:flex-row w-full gap-2'>
        <div onClick={() => setDropdownOpen(true)}>
          {selected && (
            <div
              key={selected.id}
              className='flex p-2 items-center bg-gray-100 h-8 rounded-md hover:border-primary-300 border'
              onClick={() => handleSelect(selected)}
            >
              <RenderSVG
                Svg={CurrencyDollar}
                className='w-5 h-5 rounded-full mr-2 text-primary-600'
              />
              <span className='text-sm truncate'>
                {selected.name} ({selected.costCode})
              </span>
              <button
                name="costCodes"
                onClick={(e) => {
                  handleRemove(e);
                  e.stopPropagation();
                }}
                className='ml-2 text-sm font-bold text-primary-600 hover:text-primary-800 cursor-pointer'
              >
                x
              </button>
            </div>
          )}
          {!selected && (
            <input
              type='text'
              onChange={handleOnChange}
              placeholder={inputValue ? "" : "Search Cost Code"}
              className={`border border-gray-300 rounded-md w-fit pl-4 h-8 ${
                selected ? "col-span-4" : "col-span-5"
              } focus:outline-none focus:ring-0`}
              value={inputValue}
            />
          )}
        </div>
        {role === AUTH_ROLES.ADMIN && (
          <div className="flex items-center align-center gap-2">
            OR
            <button
              className='text-white font-medium disabled:cursor-not-allowed disabled:bg-primary-400 px-2 h-8 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm whitespace-nowrap'
              onClick={registerNewCostCode}
            >
              Add Cost Code
            </button>
          </div>
        )}
      </div>
      {dropdownOpen && (
        <div
          ref={optionsRef}
          className='flex border border-gray-300 mt-1 w-2/3 overflow-y-auto absolute top-12 z-[1000] left-0 bg-white max-h-40 min-h-10 transition-all duration-300 ease-in-out transform origin-top scale-y-100 opacity-100 rounded-md shadow-md focus:border-primary-300'
        >
          <ul className='w-full'>
            {results.length > 0 ? (
              results
                .filter((item) => !selected || selected.id !== item.id)
                .map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelect(item)}
                    className='p-2 flex items-center cursor-pointer hover:bg-gray-100 w-full hover:text-primary-400'
                  >
                    <RenderSVG
                      Svg={CurrencyDollar}
                      className='w-5 h-5 rounded-full mr-2 text-primary-600'
                    />
                    <span className='truncate'>
                      {item.name} ({item.costCode})
                    </span>
                  </li>
                ))
            ) : (
              (!searchTriggered && !isFetching) && (
                <div className="flex items-center ml-4 p-1">
                  No results found for "{inputValue}"
                </div>
              )
            )}
            <li className='p-2 flex items-center justify-center w-full'>
              <Spinner name={COST_CODES} />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AddCostCodeSelector;
