import { useSelector } from "react-redux";
import { get } from "lodash";
import { selectSelectedTrip } from "../Trips";
import { selectCountryInfo } from "../../Profile";
import {
  formatPriceWithCommas,
  getDateMoreThanSixMonthsAhead,
  getFormattedDateObject,
  getRoomTypeDetails
} from "../../../helper";
import { DEFAULT_VALUES, INDEX, BOOKING_STATUS, PAYMENT_STATUS } from "../../../constants";
import { getBorderClassForStatus, getBackgroundClassForFlightorHotelName, getBackgroundClassForStatus } from "./GetClassForFlightAndHotelStatus";
import { HotelImg, InfoIcon, RenderSVG } from "../../../assets/icons";
import NoSelectedTripData from "./NoSelectedTripData";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { useLocation } from "react-router-dom";

const { EMPTY_ARRAY, ZERO, EMPTY_STRING } = DEFAULT_VALUES;
const { PENDING } = PAYMENT_STATUS;
const { FAILED } = BOOKING_STATUS;
const { AWAITING_PAYMENT } = BOOKING_STATUS;
const IMAGE_URL = "https://b2b.tektravels.com/Images/HotelNA.jpg";
const phoneNumberRegex = /Phone No:\s*([\+\d\-]+)/;

const getPhoneNumber = (addressLine) => {
  // Extract the phone number from address line string
  if (addressLine !== null) {
    const match = addressLine?.match(phoneNumberRegex);
    return match ? match[1].trim() : EMPTY_STRING;
  }
};

const getTotalPrice = (hotelRoomsDetails) => {
  const totalOfferedPrice = hotelRoomsDetails.reduce(
    (acc, { price }) => acc + (price?.offeredPrice || ZERO) + (price?.tavaMarkup || ZERO),
    ZERO
  );
  return totalOfferedPrice;
};

const SelectedHotelTrip = () => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedTripData = useSelector(selectSelectedTrip);
  const location = useLocation();
  const isGenerateVoucherBooking = location.search.includes("?razorpay_payment_id")

  const currencySymbol = selectedCountryInfo?.currency?.symbol;

  const hotelsData = selectedTripData?.hotels;

  const hotelRoomsDetails = get(
    hotelsData,
    "0.bookingReqJson.hotelRoomsDetails",
    EMPTY_ARRAY
  );
  const hotelPayLaterPaymentStatus = get(hotelsData, '[0].paymentStatus');
  const isVoucheredBooking = get(hotelsData, '[0].isVoucheredBooking');
  const lastVoucherDate = get(hotelsData, '[0].blockRoomResJson.blockRoomResult.hotelRoomsDetails[0].lastVoucherDate')
  const lastPaymentDate = getDateMoreThanSixMonthsAhead(lastVoucherDate);
  const totalPrice = getTotalPrice(hotelRoomsDetails);
  return (
    <div className=" flex flex-col gap-6">
      {hotelsData?.length > 0 &&
        hotelsData?.map((hotel, hotelIndex) => {
          if (!hotel) return;

          const { blockRoomResJson } = hotel;
          const { blockRoomResult } = blockRoomResJson;
          const { addressLine1, addressLine2, hotelName, hotelImage, hotelRoomsDetails } = blockRoomResult;
          const roomTypeData = getRoomTypeDetails(hotelRoomsDetails)

          let checkIn = getFormattedDateObject(hotel?.blockRoomReqJson?.checkInDate?.replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$2-$1'));
          let checkOut = getFormattedDateObject(hotel?.blockRoomReqJson?.checkOutDate?.replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$2-$1'));
          const phoneNo = getPhoneNumber(addressLine2);

          const selectedHotelStatus = hotelsData && hotelsData[hotelIndex]?.tenantBookingStatus;

          return (
            <div
              key={hotel?.id}
              className={classNames(
                `border rounded-lg overflow-hidden`,
                getBorderClassForStatus(selectedHotelStatus)
              )}
            >
              <div>
              {selectedHotelStatus &&(
                <div className={classNames(
                    `flex items-center justify-between text-white pl-4 leading-4 font-semibold text-xs py-2`,
                    getBackgroundClassForStatus(selectedHotelStatus)
                )}
              > 
                  {selectedHotelStatus === AWAITING_PAYMENT? "AWAITING PAYMENT" : selectedHotelStatus}
                    {hotelPayLaterPaymentStatus === PENDING && selectedHotelStatus !== FAILED  && !isVoucheredBooking && <div className="flex items-center">
                      <span
                          className={`px-3 py-1 mx-2 bg-yellow-100 text-yellow-800 font-medium rounded-full text-xs uppercase tracking-wide border border-transparent hover:border-gray-300 transition duration-150`}
                        >
                          Payment Due
                        </span>
                      {(isGenerateVoucherBooking && <div
                        data-tooltip-id={"refresh"}
                        data-tooltip-place='top'
                        data-tooltip-content={"Please refresh the page to view updated information."}
                        className="mr-2 text-base"
                      >
                        <Tooltip
                          id={"refresh"}
                          className='!sm:w-72 !bg-primary-900 !rounded-lg !z-50'
                        />
                        <RenderSVG
                          Svg={InfoIcon}
                          width='30'
                          height='30'
                          alt='Info icon'
                          color="#FFFFFF"
                          className="animate-pulse transition-opacity duration-1000 ease-in-out bg-white rounded-full"
                        />
                      </div>)}
                    </div>
                    }
                  </div>
                )}
              </div>
              {(hotelPayLaterPaymentStatus === PENDING && !isVoucheredBooking && selectedHotelStatus !== FAILED) && <div className="flex gap-1 ml-0 w-full items-center bg-gradient-to-r from-orange-600 to-green-50 text-white px-2 text-xs py-1">
                <span className="text-xs">
                  {`Payment must be completed before the due date ${lastPaymentDate[0]} ${lastPaymentDate[1]}`}
                </span>
              </div>}
              <div
                className={classNames(
                  `flex items-center justify-between p-4`,
                  getBackgroundClassForFlightorHotelName(selectedHotelStatus)
                )}
              >
                <div>
                  <h6 className='text-xl font-semibold text-gray-900'>
                    {hotelName}
                  </h6>
                  <p className='text-base text-gray-500'>{addressLine1}</p>
                </div>
                <div className='flex items-center gap-4'>
                  <div className='text-end'>
                    <p className='font-semibold text-contrast-900 text-2xl'>
                      {`${currencySymbol} ${formatPriceWithCommas(totalPrice)}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className='p-4 bg-white'>
                <div className='grid grid-cols-12 gap-4'>
                  <div className='col-span-3'>
                    {/* TODO: Need to show the Selected hotel Image by mapping the image in Request body. */}
                    <img
                      src={hotelImage || IMAGE_URL}
                      alt={hotelName}
                      className='aspect-video rounded-lg max-h-[200px]'
                    />
                  </div>
                  <div className='col-span-9 flex flex-col lg:gap-2'>
                    <div className='grid grid-cols-2 gap-4 mb-2'>
                      <div className='col-span-1'>
                        <h6 className='text-lg text-gray-900 font-semibold'>
                          {checkIn ? (
                            <>
                              {checkIn.day}{" "}
                              <strong className='text-sm'>
                                {`${checkIn.date} ${checkIn.month} `}
                              </strong>
                              {checkIn.year}
                            </>
                          ) : (
                            "..."
                          )}
                        </h6>
                        <p className='text-xs text-gray-600'>Check-In</p>
                      </div>
                      <div className='col-span-1'>
                        <h6 className='text-lg text-gray-900 font-semibold'>
                          {checkOut ? (
                            <>
                              {checkOut.day}{" "}
                              <strong className='text-sm'>
                                {`${checkOut.date} ${checkOut.month} `}
                              </strong>
                              {checkOut.year}
                            </>
                          ) : (
                            "..."
                          )}
                        </h6>
                        <p className='text-xs text-gray-600'>Check-Out</p>
                      </div>
                    </div>
                    {roomTypeData && (<h6 className="w-100 line-clamp-1"><span className="font-semibold">Room Type: </span> {roomTypeData}</h6>)}
                    {phoneNo && (
                      <div>
                        <h6 className='text-lg text-gray-900 font-semibold'>
                          {phoneNo}
                        </h6>
                        <p className='text-xs text-gray-600'>Phone Number</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          )
        })}

      {!hotelsData?.length &&
        <NoSelectedTripData icon={HotelImg} text={'Find your perfect stay!'} />
      }
    </div>
  );
};

export default SelectedHotelTrip;
