import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  checkingPaxValidation,
  checkingContactAndAddress,
  getPaxErrorMessage,
} from "../../../helper";
import { selectFlightPriceInfo } from "../../FlightResults";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import Spinner, {
  SPINNER_NAMES,
  selectActiveSpinners,
} from "../../../components/organisms/Spinner";
import { SSR_BUTTONS, DEFAULT_VALUES } from "../../../constants";
import { actions } from "./flightBookings.reducer";
import { isEmpty } from "lodash";

const { removeInvalidPax } = actions;
const { SPECIAL_SERVICES, BOOK_FLIGHT, REISSUANCE_FLIGHT, FETCH_HOTEL_RESULTS } = SPINNER_NAMES;
const { BAGGAGES, SAVE } = SSR_BUTTONS;
const { TIMEOUT_DURATION } = DEFAULT_VALUES;

const ContinueButton = ({
  name,
  message,
  customMessage,
  shouldShowButton,
  isContinueDisabled = false,
  handleContinue,
  customOnClick = () => {},
  paxVerificationData = {},
  setTravelerSectionHighlighted = () => {},
  setScrollOnError = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const flightPriceInfo = useSelector(selectFlightPriceInfo);
  const activeSpinners = useSelector(selectActiveSpinners);
  const spinnersList = [SPECIAL_SERVICES];
  const isPaymentButtonDisabled = activeSpinners.some((spinnerName) =>
    spinnersList.includes(spinnerName)
  );
  const {
    travelerValidationSchema,
    selectedTravelers,
    selectedAddressAndContactDetails,
  } = paxVerificationData;
  const [showSpinner, setShowSpinner] = useState(false);

  const showErrorMessage = (errorInfo, isValidationError) => {
    setShowError(isValidationError);
    setTravelerSectionHighlighted(true);
    setScrollOnError(errorInfo);
    setTimeout(() => {
      setShowError(false);
      setTravelerSectionHighlighted(false);
      setScrollOnError({});
    }, TIMEOUT_DURATION);
  };

  const handleOnClick = async() => {
    setShowSpinner(true);
    customOnClick();
    const inValidPaxData = checkingPaxValidation(
      travelerValidationSchema,
      selectedTravelers
    );
    const inValidPaxIds = Object.keys(inValidPaxData);
    dispatch(removeInvalidPax(inValidPaxIds));
    const isPaxDetailsValidated = isEmpty(inValidPaxData);
    const isValidationError = isContinueDisabled || !isPaxDetailsValidated;
    const contactAndAddressErrorDetails = !isValidationError
      ? checkingContactAndAddress(selectedAddressAndContactDetails)
      : {};
    const errorMessage = getPaxErrorMessage(
      message,
      customMessage,
      name,
      flightPriceInfo,
      t,
      isPaxDetailsValidated
    );
    setShowSpinner(false);
    if (isValidationError || !isEmpty(contactAndAddressErrorDetails)) {
      errorMessage && setErrorMessage(errorMessage || "There was an Issue with the Traveler Details. Please Check and Resubmit.");
      showErrorMessage(contactAndAddressErrorDetails, isValidationError);
    }else {
      await handleContinue();
    }
  };
  return (
    <div className='my-4'>
      {shouldShowButton && (
        <div className='my-4 w-full flex gap-2 align-text-bottom'>
          <button
            type='button'
            className='h-10 py-2 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 border border-contrast-300 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:opacity-70'
            onClick={handleOnClick}
            disabled={showSpinner}
          >
            {name === BAGGAGES || name === SAVE ? (
              <Spinner
                showSpinner={showSpinner}
                name={[SPECIAL_SERVICES, BOOK_FLIGHT, FETCH_HOTEL_RESULTS, REISSUANCE_FLIGHT]}
                persistSize={true}
                setIsSpinnerActive={setShowSpinner}
              >
                <div className='flex'>
                  {t("travelerInfo.continueButton.save")}
                </div>
              </Spinner>
            ) : (
              t("travelerInfo.continueButton.continue")
            )}
          </button>
          {showError && (
            <ErrorMessage errorMessage={errorMessage} className='font-bold' />
          )}
        </div>
      )}
    </div>
  );
};

export default ContinueButton;
