import { camelCase, mapKeys } from "lodash";

export const convertKeysToCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase);
  } else if (obj !== null && obj && typeof obj === "object") {
    const payload = mapKeys(obj, (value, key) => camelCase(key));
    return payload;
  }
  return obj;
};
