export const getDateMoreThanSixMonthsAhead = (givenDate) => {
    const currentDate = new Date();
    const targetDate = new Date(givenDate);

    const sixMonthsFromNow = new Date(currentDate);
    sixMonthsFromNow.setMonth(currentDate.getMonth() + 6);

    const earlierDate = targetDate < sixMonthsFromNow ? targetDate : sixMonthsFromNow;
    return earlierDate.toISOString().split('.')[0].split('T');
}
