import { get } from "lodash";

export const getHotelBookingAmount = (hotels) => {
  const hotelRoomDetails =
    get(hotels, "0.blockRoomReqJson.hotelRoomsDetails", []) ||
    get(trips, "0.blockRoomResJson.blockRoomResult.hotelRoomsDetails", []);
  const totalFare = hotelRoomDetails.reduce((acc, room) => {
    const priceDetails = room.price || {};
    const tavaMarkup = priceDetails.tavaMarkup || 0;
    const offeredPrice = priceDetails.offeredPrice || 0;
    const totalPrice = offeredPrice + tavaMarkup;
    return acc + totalPrice;
  }, 0);
  return totalFare;
};
