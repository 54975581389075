import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import classNames from "classnames";
import { toast } from "react-toastify";
import {
  DEFAULT_VALUES,
  INDEX,
  BOOKING_STATUS,
  PAYMENT_PROVIDERS,
  RAZORPAY_CHECKOUT_SCRIPT_URL,
  DEFAULT_CURRENCY_CODE,
} from "../../../constants";
import {
  getBorderClassForStatus,
  getBackgroundClassForFlightorHotelName,
  getBackgroundClassForStatus,
} from "./GetClassForFlightAndHotelStatus";
import {
  getAirlineIconUrlByCarrierCode,
  getLayoverDuration,
  getCityNameByIata,
  calculateTotalDuration,
  priceFormatter,
  loadScript,
} from "../../../helper";
import {
  initiateBookCallback,
  initiatePayment,
  selectSelectedTrip,
} from "../Trips";
import { getStops } from "../../../utils/flights.utils";
import { selectCountryInfo, selectUserInfo } from "../../Profile";
import { PlaneImg } from "../../../assets/icons";
import NoSelectedTripData from "./NoSelectedTripData";
import { useState } from "react";
import PaymentProviderModal from "../../../components/organisms/AppModals/PaymentProviderModal";
import { useNavigate } from "react-router-dom";
import { selectCurrentUserInfo } from "../../Auth";

const { ZERO, ONE, EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { LAST } = INDEX;
const { CONFIRMED, AWAITING_PAYMENT, IN_REVIEW, DRAFT, FAILED } =
  BOOKING_STATUS;
const { RAZORPAY, WALLET } = PAYMENT_PROVIDERS;
const ADULT_TRAVELER_TYPE = "ADULT";
const FLIGHT_BOOK_TYPE = "FLIGHT";
const PAYMENT_PROCESS_ERROR = "An error occurred while processing the payment";
const RenderReissuanceStatus = ({ handleSubmit, status }) => {
  switch (status) {
    case CONFIRMED:
      return (
        <h6 className='text-xl font-semibold capitalize'>
          <p className='text-green-800'>Confirmed</p>
        </h6>
      );
    case FAILED:
      return (
        <h6 className='text-xl font-semibold capitalize'>
          <p className='text-red-800'>Failed</p>
        </h6>
      );
    default:
      return (
        <button
          className='capitalize py-2 px-4 inline-flex items-center gap-2 text-center rounded-md bg-red-100 hover:bg-red-200 active:bg-red-100 border-none shadow-sm text-sm text-red-700 font-medium'
          onClick={handleSubmit}
        >
          payment & book
        </button>
      );
  }
};

const ReissuanceDetails = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const userInfo = useSelector(selectUserInfo);
  const currencySymbol = selectedCountryInfo?.currency?.symbol;
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const {
    bookingJson,
    tavaBookingId,
    id: reIssuanceBookingId,
    ticketingStatus,
  } = data;
  const selectedFlightDetails = get(
    bookingJson,
    "journeyDetails.0",
    EMPTY_OBJECT
  );
  const {
    itineraries = [],
    price = EMPTY_OBJECT,
    travelerDetails = EMPTY_ARRAY,
  } = selectedFlightDetails;
  const { segments = [] } = itineraries?.at(ZERO) ?? {};
  const { departure = {} } = segments.at(ZERO) ?? {};
  const { grandTotal = ZERO } = price;
  const currency = get(userInfo, "tenant.currency", DEFAULT_CURRENCY_CODE);
  const {
    email,
    givenName: name,
    phoneNumber: contact,
  } = travelerDetails.find(
    (traveler) => traveler.travelerType === ADULT_TRAVELER_TYPE
  ) || EMPTY_OBJECT;
  const paymentRoute = `/bookings/${tavaBookingId}/payment`;
  const myTripPaymentRoute = `/users/${currentUserInfo?.id}/bookings/${tavaBookingId}/payment`;
  const reissuanceCallbackRoute = `/reissuance-callback/${tavaBookingId}`;
  const isPaymentRequired = Number(grandTotal) > ZERO;

  const handleClose = () => setShouldShowModal(false);
  // Verify if payment required to book reissuance flight.
  const handleonSubmit = () => {
    if (isPaymentRequired) setShouldShowModal(true);
    else {
      const bookCallbackRequest = {
        isPaymentRequired,
        reIssuanceBookingId,
      };
      const bookCallbackRequestBody = {
        requestBody: bookCallbackRequest,
        url: reissuanceCallbackRoute,
      };
      dispatch(initiateBookCallback(bookCallbackRequestBody));
      if (
        window.location.pathname.includes(
          `/users/${currentUserInfo?.id}/bookings`
        )
      ) {
        navigate(`${myTripPaymentRoute}/confirm`);
      } else {
        navigate(`${paymentRoute}/confirm`);
      }
    }
  };
  const handleBookCallback = (paymentAPIResponse, paymentAPIRequest) => {
    const { id, transactionId, currency } = paymentAPIResponse;
    const { paymentMethod, amount } = paymentAPIRequest;
    const bookCallbackRequest = {
      isPayLater: false,
      paymentSessionId: id,
      transactionId,
      currency,
      amount,
      paymentMethod,
      isPaymentRequired,
      reIssuanceBookingId,
    };
    const bookCallbackRequestBody = {
      requestBody: bookCallbackRequest,
      url: reissuanceCallbackRoute,
    };

    dispatch(initiateBookCallback(bookCallbackRequestBody)).then((res) => {
      if (res.payload){ 
        if (
          window.location.pathname.includes(
            `/users/${currentUserInfo?.id}/bookings`
          )
        ) {
          navigate(`${myTripPaymentRoute}/confirm`);
        } else {
          navigate(`${paymentRoute}/confirm`);
        }
      }
    });
  };
  const handleRazorpayPayment = async (paymentAPIRequest) => {
    const { amount } = paymentAPIRequest;
    if (Number(amount) <= ZERO) return;
    const loadedScript = await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);
    if (!loadedScript) return;
    try {
      dispatch(initiatePayment(paymentAPIRequest)).then(({ payload }) => {
        if (!payload) return;
        const isUserBookingPath = window.location.pathname.includes(
          `/users/${currentUserInfo?.id}/bookings`
        );
        const paymentData = {
          successURL: isUserBookingPath
              ? `/users/${currentUserInfo?.id}/bookings/${tavaBookingId}/payment/confirm`
              : `/bookings/${tavaBookingId}/payment/confirm`,
            abortURL: isUserBookingPath
              ? `/users/${currentUserInfo?.id}/bookings/${tavaBookingId}`
              : `/bookings/${tavaBookingId}`,
          callbackURL: `/reissuance-callback/${tavaBookingId}`,
          payload: {
            isPaymentRequired,
            reIssuanceBookingId,
            amount,
          },
          traveler: { email, name, contact },
        };
        if (
          window.location.pathname.includes(
            `/users/${currentUserInfo?.id}/bookings`
          )
        ) {
          navigate(`${myTripPaymentRoute}`, { state: paymentData });
        } else {
          navigate(`${paymentRoute}`, { state: paymentData });
        }
        handleClose();
      });
    } catch (error) {
      toast.error(PAYMENT_PROCESS_ERROR);
    }
  };
  const handleWalletPayment = (paymentAPIRequest) => {
    try {
      dispatch(initiatePayment(paymentAPIRequest)).then(({ payload }) => {
        if (!payload) return;
        handleBookCallback(payload, paymentAPIRequest);
        handleClose();
      });
    } catch (error) {
      toast.error(PAYMENT_PROCESS_ERROR);
    }
  };
  const handlePayment = ({ provider, totalCharges }) => {
    const { id, name } = provider;
    const paymentAPIRequest = {
      tavaBookingId,
      paymentMethod: name,
      bookingType: FLIGHT_BOOK_TYPE,
      amount: totalCharges,
      currency,
      paymentProviderId: id,
    };

    if (name === RAZORPAY) handleRazorpayPayment(paymentAPIRequest);
    else if (name === WALLET) handleWalletPayment(paymentAPIRequest);
  };

  return (
    <>
      <div
        className={classNames(
          `flex items-center justify-between p-4 bg-green-200`
        )}
      >
        <h6 className='text-xl font-semibold'>
          <p className='text-red-800'>Reissued Booking</p>{" "}
          <div className='text-gray-900'>
            <p className='text-base 2xl:text-lg font-semibold text-gray-900'>
              {departure.date}
            </p>
            <p className='text-base 2xl:text-lg font-semibold text-gray-900'>
              {departure.time}
            </p>
          </div>
        </h6>
        <div className='flex items-center gap-4'>
          <div className='text-end'>
            <p className='font-semibold text-gray-900  text-2xl'>
              {`${currencySymbol} ${priceFormatter(grandTotal)}`}
            </p>
            <RenderReissuanceStatus
              handleSubmit={handleonSubmit}
              status={ticketingStatus}
            />
          </div>
        </div>
      </div>
      {shouldShowModal && (
        <PaymentProviderModal
          payableAmount={grandTotal}
          handleOnPay={handlePayment}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

const FlightCard = ({ data }) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = selectedCountryInfo?.currency?.symbol;

  const {
    bookingJson,
    tenantStatus,
    id,
    reissuedBookings = EMPTY_ARRAY,
  } = data;
  const selectedFlightDetails = get(
    bookingJson,
    "journeyDetails.0",
    EMPTY_OBJECT
  );
  const reissuanceFlight = reissuedBookings[0];
  const { itineraries = [], price = EMPTY_OBJECT } = selectedFlightDetails;
  const { segments = [] } = itineraries?.at(ZERO) ?? {};
  const { departure = {} } = segments.at(ZERO) ?? {};
  const finalDestination = segments.at(LAST) ?? {};
  const { arrival = {} } = finalDestination || EMPTY_OBJECT;
  const { grandTotal = ZERO } = price;

  return (
    <div
      className={classNames(
        `border rounded-lg overflow-hidden`,
        getBorderClassForStatus(tenantStatus)
      )}
    >
      {tenantStatus && (
        <div
          className={classNames(
            `text-white pl-4 leading-4 font-semibold text-xs py-2`,
            getBackgroundClassForStatus(tenantStatus)
          )}
        >
          {tenantStatus === AWAITING_PAYMENT
            ? "AWAITING PAYMENT"
            : tenantStatus}
        </div>
      )}
      {reissuanceFlight && <ReissuanceDetails data={reissuanceFlight} />}
      <div
        className={classNames(
          `flex items-center justify-between p-4`,
          getBackgroundClassForFlightorHotelName(tenantStatus)
        )}
      >
        <h6 className='text-xl font-semibold text-gray-900'>
          {getCityNameByIata(departure.iataCode) + "," + departure.iataCode} -{" "}
          {getCityNameByIata(arrival.iataCode) + "," + arrival.iataCode}
        </h6>
        <div className='flex items-center gap-4'>
          <div className='text-end'>
            <p className='font-semibold text-gray-900  text-2xl'>
              {`${currencySymbol} ${priceFormatter(grandTotal)}`}
            </p>
          </div>
        </div>
      </div>
      <div className='px-4 bg-white divide-y divide-gray-200'>
        {itineraries.map((itinerary, index) => (
          <FlightDetails itinerary={itinerary} key={index} />
        ))}
      </div>
    </div>
  );
};

const FlightDetails = ({ itinerary }) => {
  const { segments, duration } = itinerary;
  const {
    flightNumber,
    aircraftCode,
    carrierCode,
    cabinClass,
    departure,
    carrierName,
  } = segments[ZERO];
  const finalDestination = segments.at(LAST);
  const { arrival = {} } = finalDestination;
  const totalNoOfStops = segments.length - ONE;
  const layoverDuration = getLayoverDuration(segments);

  return (
    <div className='py-4'>
      <h6 className='text-base 2xl:text-xl text-gray-900 font-semibold mb-4'>
        {departure.date}
      </h6>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-12 lg:col-span-5 xl:col-span-4 2xl:col-span-5'>
          <div className='flex items-center gap-4'>
            <div className='logo'>
              <img
                src={getAirlineIconUrlByCarrierCode(carrierCode)}
                className='rounded border-contrast-300 w-8 h-8'
                alt={`${carrierCode}_logo`}
              />
            </div>
            <div className='flex-1'>
              <h5 className='text-base 2xl:text-lg font-semibold text-gray-900 mb-1'>
                {departure.time} - {arrival.time}
              </h5>
              <div className='text-xs text-gray-600'>
                {carrierName || carrierCode}, {flightNumber}{" "}
                {aircraftCode ? `| ${aircraftCode}` : ""}
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-12 lg:col-span-7 xl:col-span-8 2xl:col-span-7'>
          <div className=' flex gap-4'>
            <div className='flex-1'>
              <h5 className='text-base 2xl:text-lg text-gray-900 font-semibold mb-1'>
                {calculateTotalDuration(layoverDuration, duration)}
              </h5>
              <p className='text-xs text-gray-600'>
                {getCityNameByIata(departure.iataCode)} -{" "}
                {getCityNameByIata(arrival.iataCode)}
              </p>
            </div>
            <div className='flex-1 text-end sm:text-start'>
              {!totalNoOfStops ? (
                <h5 className='text-base 2xl:text-lg text-gray-900 font-semibold mb-1'>
                  Non Stop
                </h5>
              ) : (
                <>
                  <h5 className='text-base 2xl:text-lg text-gray-900 font-semibold mb-1'>
                    {totalNoOfStops} Stop
                  </h5>
                  <p className='text-xs text-gray-600'>{getStops(segments)}</p>
                </>
              )}
            </div>
            <div className='flex-1 text-end sm:text-start'>
              <h5 className='text-base 2xl:text-lg text-gray-900 font-semibold mb-1'>
                {cabinClass}
              </h5>
              <p className='text-xs text-gray-600'>
                {carrierName || carrierCode}, {flightNumber}{" "}
                {aircraftCode ? `| ${aircraftCode}` : EMPTY_STRING}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectedFlightTrip = () => {
  const selectedTrip = useSelector(selectSelectedTrip);
  const { flights } = selectedTrip || EMPTY_OBJECT;

  return (
    <div className='flex flex-col gap-6'>
      {flights.map((flight) => (
        <FlightCard data={flight} key={flight.id} />
      ))}
      {!flights.length && (
        <NoSelectedTripData icon={PlaneImg} text={"Add your first flight!"} />
      )}
    </div>
  );
};

export default SelectedFlightTrip;
