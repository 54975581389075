import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import MealsCard from "./MealsCard";
import TravelerMealCard from "./TravelerMealCard";
import { selectSpecialServices } from "../index";
import Tabs from "../../../../components/organisms/Tabs/Tabs";
import { EditIcon, RenderSVG } from "../../../../assets/icons";
import { DEFAULT_VALUES } from "../../../../constants";
import { getSegmentsTitle, getSegmentCityName } from "../../../../helper";
import { selectFlightPriceReq } from "../../../FlightResults";

const { ZERO, EMPTY_ARRAY } = DEFAULT_VALUES;
const TRANSITION_TIMEOUT = 1000;

const MealSelection = ({ selectionProgress, setSelectionProgress }) => {
  const { t } = useTranslation();
  const { getCollapseProps, getToggleProps, setExpanded, isExpanded } =
    useCollapse({
      duration: 400,
      defaultExpanded: false,
    });
  const [isCompleted, setIsCompleted] = useState(false);
  const [shouldEaseOut, setShouldEaseOut] = useState(false);
  const [mealTabs, setMealTabs] = useState(EMPTY_ARRAY);

  const specialServices = useSelector(selectSpecialServices);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;

  const { travelerSelection, seatSelection, mealSelection } = selectionProgress;

  useEffect(() => {
    if (!specialServices) return;
    let mealSelectionTabs = [];
    specialServices.forEach(
      (
        { data: { mealDynamic = [], meal = [] }, isLCC, resultIndex },
        index
      ) => {
        if (isLCC && !isEmpty(mealDynamic)) {
          const tabs = mealDynamic.map((segment) => {
            const sortedSegment = segment?.toSorted((a, b) =>
              a.Price > b.Price ? 1 : -1
            );
            const data = [{origin:segment[0].origin,destination:segment[segment.length-1].destination}]
            const formattedTabs = sortedSegment.length && {
              id: sortedSegment[ZERO]?.flightNumber,
              title: getSegmentCityName(data),
              element: sortedSegment.map((foodItem) => (
                <MealsCard
                  key={foodItem.airlineDescription}
                  data={foodItem}
                  isLCC={isLCC}
                />
              )),
              isLCC,
            };
            return formattedTabs;
          });
          mealSelectionTabs = [...mealSelectionTabs, ...tabs];
        } else if (!isLCC && !isEmpty(meal)) {
          const allSegmentTitle = getSegmentsTitle(flightPriceReq, resultIndex);
          const requiredTitle = allSegmentTitle.join(", ");
          const tab = {
            id: resultIndex,
            title: requiredTitle,
            element: meal.map((foodItem) => (
              <MealsCard key={foodItem.code} data={foodItem} isLCC={isLCC} />
            )),
            isLCC,
          };
          mealSelectionTabs = [...mealSelectionTabs, tab];
        }
      }
    );
    if (!isEmpty(mealSelectionTabs)) mealSelectionTabs[ZERO].default = true;
    setMealTabs(mealSelectionTabs);
  }, [specialServices, flightPriceReq]);

  useEffect(() => {
    if (shouldEaseOut)
      setTimeout(() => setShouldEaseOut(false), TRANSITION_TIMEOUT);
  }, [shouldEaseOut]);

  useEffect(() => {
    setExpanded(travelerSelection && seatSelection && !mealSelection);
    setIsCompleted(travelerSelection && seatSelection && mealSelection);
  }, [travelerSelection, seatSelection, mealSelection]);

  return (
    <div className='gap-6'>
      <div
        className={classNames(
          "text-contrast-900 text-lg font-bold p-6 border bg-white border-contrast-200 flex justify-between",
          {
            "rounded-lg": !isExpanded,
            "rounded-t-lg": isExpanded,
            "opacity-60": !isCompleted && !isExpanded,
            "opacity-100": isCompleted || isExpanded,
          }
        )}
      >
        {t("mealSelection.title")}
        <button
          onClick={() =>
            setSelectionProgress({ ...selectionProgress, mealSelection: false })
          }
        >
          {isCompleted && (
            <RenderSVG
              Svg={EditIcon}
              className='w-6 h-6'
              alt='Toggle dropdown'
              {...getToggleProps()}
            />
          )}
        </button>
      </div>
      {!isEmpty(mealTabs) && (
        <div {...getCollapseProps()}>
          <div className='bg-white p-3 rounded-b-lg border border-contrast-200 flex gap-3 justify-between w-full flex-col md:flex-row lg:flex-col xl:flex-row'>
            <div className='max-h-[500px] overflow-y-auto'>
              <Tabs tabs={mealTabs} tabStyles={{ sticky: true }}/>
            </div>
            <TravelerMealCard tabs={mealTabs} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MealSelection;
