import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { selectSelectedTrip, setSelectedTrip, initiateBookCallback, updateHotelVoucherStatus } from "../../../screens/Booking/Trips";
import { DEFAULT_VALUES, PAYMENT_PROVIDERS, DEFAULT_CURRENCY_CODE } from "../../../constants";
import { MODALS } from "../AppModals";
import { setSelectedModal } from "../Modal";
import { cloneDeep, get, isEmpty } from 'lodash'
import { FlightBlue, Hotel, RenderSVG } from '../../../assets/icons';
import { selectUserInfo } from '../../../screens/Profile';
import Spinner from '../Spinner';
import { SPINNER_NAMES } from '../../organisms/Spinner/spinner.constants'
import { getNextDate, useClickOutside } from '../../../helper';
import { selectCurrentUserInfo } from '../../../screens/Auth';
import { useNavigate } from 'react-router-dom';

const {REACT_APP_HOTEL_PAY_LATER = false} = process.env;
const { UPDATE_HOTEL_VOUCHERED_STATUS } = SPINNER_NAMES
const { EMPTY_OBJECT, ZERO } = DEFAULT_VALUES;
const { TRIP_PAYMENT_MODAL } = MODALS;

const PaymentButton = ({ shouldDisable }) => {
  const navigate = useNavigate();
  const clickOutsideRef = useRef(null)
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useClickOutside(clickOutsideRef, setIsModalOpen);

  const nextDate = getNextDate(new Date());
  const selectedTrip = useSelector(selectSelectedTrip);
  const { id: tavaBookingId } = selectedTrip;
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const myTripPaymentRoute = `/users/${currentUserInfo?.id}/bookings/${tavaBookingId}/payment`;
  const userInfo = useSelector(selectUserInfo)
  const [isVoucheredBooking, setIsVoucheredBooking] = useState(get(selectedTrip, "hotels[0].isVoucheredBooking", true));
  const currency = get(userInfo, "tenant.currency", DEFAULT_CURRENCY_CODE);
  const isFlight = selectedTrip.flights.length > 0;
  const isHotel = selectedTrip.hotels.length > 0;
  const hotelRoomsDetails = get(selectedTrip, "hotels[0].blockRoomResJson.blockRoomResult.hotelRoomsDetails", [])
  const lowestLastCancellationDate = !isEmpty(hotelRoomsDetails) && hotelRoomsDetails.reduce(
    (minDate, roomDetail) => {
      const roomLastVoucherDate = new Date(roomDetail.lastVoucherDate);
      return roomLastVoucherDate < minDate ? roomLastVoucherDate : minDate;
    },
    nextDate
  );

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const isVoucherBooking = get(selectedTrip, "hotels[0].isVoucheredBooking", true);
    setIsVoucheredBooking(isVoucherBooking);
  }, [selectedTrip])

  const toShowNonVoucherBooking =
    !isEmpty(hotelRoomsDetails) && lowestLastCancellationDate >= new Date();

  const { bookType } = selectedTrip || EMPTY_OBJECT;
  const handleHotelPaymentChange = () => { 
    setIsVoucheredBooking((prev) => !prev);
  }

  const handleSubmit = () => {
    toggleModal();
    let updatedSelctedTrip = cloneDeep(selectedTrip);
    updatedSelctedTrip.hotels[0].isVoucheredBooking = isVoucheredBooking;
    if (!shouldDisable) {
      dispatch(updateHotelVoucherStatus({ id: tavaBookingId, requestBody: { isVoucheredBooking } }))
      dispatch(setSelectedTrip(updatedSelctedTrip))
      dispatch(setSelectedModal(TRIP_PAYMENT_MODAL));
    }
  }

  const handlePaymentButton = () => {
    if (isHotel && REACT_APP_HOTEL_PAY_LATER === "true" && toShowNonVoucherBooking) {
      toggleModal()
      return;
    }
    dispatch(setSelectedModal(TRIP_PAYMENT_MODAL));
  }

  const handleNonVoucheredHotel = () => {
    const bookCallbackRequest = {
      isPayLater: true,
      paymentSessionId: tavaBookingId,
      transactionId: null,
      currency,
      amount: ZERO,
      paymentMethod: PAYMENT_PROVIDERS.RAZORPAY,
    };
    const bookCallbackRequestBody = {
      requestBody: bookCallbackRequest,
      url: `/book-callback/${tavaBookingId}`,
    };
    dispatch(updateHotelVoucherStatus({ id: tavaBookingId, requestBody: { isVoucheredBooking } })).then((res) => {
      if (res.payload) {
        toggleModal();
        dispatch(initiateBookCallback(bookCallbackRequestBody));
        navigate(`${myTripPaymentRoute}/confirm`);
      }
    })
  }
  
  const isNonVoucheredHotel = isHotel && !isVoucheredBooking;

  return (
    <>
      {
        <div className='pl-4 border-l border-gray-100 relative'>
          <div
            className={classNames(
              "pl-[15px] pr-[17px] py-[9px] rounded-md shadow justify-center items-center gap-2 flex",
              {
                "cursor-pointer": !shouldDisable,
                "cursor-not-allowed": shouldDisable,
                "bg-contrast-300": shouldDisable,
                "bg-indigo-600": !shouldDisable,
              }
            )}
            onClick={handlePaymentButton}
          >
            <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
              Proceed to Book
            </div>
          </div>
          {isHotel && (REACT_APP_HOTEL_PAY_LATER === "true") && toShowNonVoucherBooking && isModalOpen && (
            <div ref={clickOutsideRef} className='flex flex-col w-64 right-8 absolute p-4 bg-white rounded-md shadow-md border border-gray-200'>
              <h2 className='text-lg font-medium mb-4'>Payment Type</h2>
              {isFlight && (<>
                <div className='mb-4 flex flex-col gap-2'>
                  <h3 className='flex items-center gap-2 text-lg font-semibold text-primary-700'>
                    <RenderSVG Svg={FlightBlue} width='16' height='16' alt='trip_icon' />
                    Flight
                  </h3>
                  <p className='text-sm text-gray-700'>
                    Your flight booking currently has the 'pay-now' option selected.
                  </p>
                </div>
                <div className='border-b-2 border-dashed border-gray-200 mb-4' />
              </>)}
                <div className='mb-4 flex flex-col gap-2'>
                  <h3 className='flex items-center gap-2 text-lg font-semibold text-primary-700'>
                    <RenderSVG
                      Svg={Hotel}
                      width='16'
                      height='16'
                      alt='trip_icon'
                    />
                    Hotel
                  </h3>
                  <p className='text-sm'>Choose how to pay for the hotel:</p>
                  <label className='flex items-center mb-2'>
                    <input
                      type='radio'
                      value='payNow'
                      checked={isVoucheredBooking}
                      onChange={handleHotelPaymentChange}
                      className='mr-2 peer peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600'
                    />
                    <span className='text-sm'>Pay Now</span>
                  </label>
                  {toShowNonVoucherBooking && (
                    <label className='flex items-center'>
                      <input
                        type='radio'
                        value='payLater'
                        checked={!isVoucheredBooking}
                        onChange={handleHotelPaymentChange}
                        className='mr-2 peer-hidden focus:outline-none'
                      />
                      <span className='text-sm'>Pay Later</span>
                    </label>
                  )}
                </div>
                <button
                  className='mt-2 flex items-center justify-center py-2 bg-primary-700 text-white rounded-md'
                  onClick={
                    isNonVoucheredHotel && !isFlight ? handleNonVoucheredHotel : handleSubmit
                  }
                >
                  {isNonVoucheredHotel ? (
                    <Spinner name={UPDATE_HOTEL_VOUCHERED_STATUS}>
                      Continue
                    </Spinner>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            )}
        </div>
      }
    </>
  );
};

export default PaymentButton;
