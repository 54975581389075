import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import {
  DEFAULT_CURRENCY_CODE,
  PAYMENT_PROVIDERS,
  RAZORPAY_CHECKOUT_SCRIPT_URL,
  DEFAULT_VALUES,
} from "../../../../constants";
import { actions as ModalActions } from "../../Modal/modal.reducers";
import { getTotalBookingAmountForTrip, loadScript } from "../../../../helper";
import {
  selectExchangeRates,
  selectUserInfo,
} from "../../../../screens/Profile";
import {
  initiatePayment,
  selectSelectedTrip,
  initiateBookCallback,
} from "../../../../screens/Booking/Trips";
import PaymentProviderModal from "../PaymentProviderModal";
import { selectCurrentUserInfo } from "../../../../screens/Auth";

const { setSelectedModal } = ModalActions;

const { RAZORPAY, WALLET } = PAYMENT_PROVIDERS;
const { ZERO, EMPTY_OBJECT, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const ADULT_TRAVELER_TYPE = "Adult";
const PAYMENT_PROCESS_ERROR = "An error occurred while processing the payment";

const TripPaymentModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTrip = useSelector(selectSelectedTrip);
  const isVoucherBooking = get(selectedTrip, "hotels[0].isVoucheredBooking", true);
  const exchangeRate = useSelector(selectExchangeRates);
  const userInfo = useSelector(selectUserInfo);
  const currentUserInfo = useSelector(selectCurrentUserInfo);

  const [payableAmount, setPayableAmount] = useState(ZERO);
  const currency = get(userInfo, "tenant.currency", DEFAULT_CURRENCY_CODE);
  const { bookType, id: tavaBookingId } = selectedTrip || EMPTY_OBJECT;
  const {
    phone: contact,
    email,
    firstName: name,
  } = currentUserInfo || {};
  const paymentRoute = `/bookings/${tavaBookingId}/payment`;
  const myTripPaymentRoute = `/users/${currentUserInfo?.id}/bookings/${tavaBookingId}/payment`;

  useEffect(() => {
    if (!selectedTrip) return;

    const payableAmount = getTotalBookingAmountForTrip(
      selectedTrip,
      currency,
      exchangeRate,
      isVoucherBooking,
    );
    const requiredCharges = parseFloat(payableAmount);
    setPayableAmount(requiredCharges);
  }, [selectedTrip]);
   

  const handleClose = () => dispatch(setSelectedModal(null));
  const handleBookCallback = (paymentAPIResponse, paymentAPIRequest) => {
    if (isEmpty(paymentAPIResponse)) return;
    const { id, transactionId, currency } = paymentAPIResponse;
    const { paymentMethod, amount } = paymentAPIRequest;
    const bookCallbackRequest = {
      paymentSessionId: id,
      transactionId,
      currency,
      amount,
      paymentMethod,
    };
    const bookCallbackRequestBody = {
      requestBody: bookCallbackRequest,
      url: `/book-callback/${tavaBookingId}`,
    };

    dispatch(initiateBookCallback(bookCallbackRequestBody));
    if (
      window.location.pathname.includes(
        `/users/${currentUserInfo?.id}/bookings`
      )
    ) {
      navigate(`${myTripPaymentRoute}/confirm`);
    } else {
      navigate(`${paymentRoute}/confirm`);
    }
  };
  const handleRazorpayPayment = async (paymentAPIRequest) => {
    const loadedScript = await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);
    if (!loadedScript) return;
    try {
      const { amount } = paymentAPIRequest;
      dispatch(initiatePayment(paymentAPIRequest)).then(({ payload }) => {
        handleClose();
        if (payload && Number(amount) > ZERO) {
          const isUserBookingPath = window.location.pathname.includes(
            `/users/${currentUserInfo?.id}/bookings`
          );
          const paymentData = {
            successURL: isUserBookingPath
              ? `/users/${currentUserInfo?.id}/bookings/${tavaBookingId}/payment/confirm`
              : `/bookings/${tavaBookingId}/payment/confirm`,
            abortURL: isUserBookingPath
              ? `/users/${currentUserInfo?.id}/bookings/${tavaBookingId}`
              : `/bookings/${tavaBookingId}`,
            callbackURL: `/book-callback/${tavaBookingId}`,
            payload: { amount },
            traveler: { email, name, contact},
          };

          if (
            window.location.pathname.includes(
              `/users/${currentUserInfo?.id}/bookings`
            )
          ) {
            navigate(`${myTripPaymentRoute}`, { state: paymentData });
          } else {
            navigate(`${paymentRoute}`, { state: paymentData });
          }
        }
      });
    } catch (error) {
      toast.error(PAYMENT_PROCESS_ERROR);
    }
  };
  const handleWalletPayment = (paymentAPIRequest) => {
    try {
      dispatch(initiatePayment(paymentAPIRequest)).then(({ payload }) => {
        if (!payload) return;
        handleBookCallback(payload, paymentAPIRequest);
        handleClose();
      });
    } catch (error) {
      toast.error(PAYMENT_PROCESS_ERROR);
    }
  };
  const handlePayment = ({ provider, totalCharges, gatewayCharges }) => {
    const { id, name } = provider;
    const paymentAPIRequest = {
      tavaBookingId,
      paymentMethod: name,
      bookingType: bookType,
      amount: totalCharges,
      currency: currency,
      paymentProviderId: id,
      gatewayCharges,
    };

    if (name === RAZORPAY) handleRazorpayPayment(paymentAPIRequest);
    else if (name === WALLET) handleWalletPayment(paymentAPIRequest);
  };

  return (
    <PaymentProviderModal
      payableAmount={payableAmount}
      handleOnPay={handlePayment}
      handleClose={handleClose}
      showWallet={isVoucherBooking}
    />
  );
};

export default TripPaymentModal;
