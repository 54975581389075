import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { get } from "lodash";
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
} from "../../../../constants";
import { actions as ModalActions } from "../../Modal/modal.reducers";
import { getHotelBookingAmount} from "../../../../helper";
import {
  selectExchangeRates,
  selectUserInfo,
} from "../../../../screens/Profile";
import {
  selectSelectedTrip,
} from "../../../../screens/Booking/Trips";
import PaymentProviderModal from "../PaymentProviderModal";

const { setSelectedModal } = ModalActions;
const { ZERO } = DEFAULT_VALUES;

const TripPaymentModal = () => {
  const dispatch = useDispatch();
  const selectedTrip = useSelector(selectSelectedTrip);
  const exchangeRate = useSelector(selectExchangeRates);
  const userInfo = useSelector(selectUserInfo);
  const paymentLink = get(selectedTrip, "hotels[0].metadata.short_url" , null);
  const [payableAmount, setPayableAmount] = useState(ZERO);
  const currency = get(userInfo, "tenant.currency", DEFAULT_CURRENCY_CODE);

  useEffect(() => {
    if (!selectedTrip) return;
    const payableAmount =  getHotelBookingAmount(selectedTrip?.hotels || [])
    const requiredCharges = parseFloat(payableAmount.toFixed(2));
    setPayableAmount(requiredCharges);
  }, [selectedTrip]);
  
  const handleClose = () => dispatch(setSelectedModal(null));

  return (
    <PaymentProviderModal
      payableAmount={payableAmount}
      handleOnPay={()=> {
        window.location.href = paymentLink;
        dispatch(setSelectedModal(null))
      }}
      handleClose={handleClose}
      showWallet={false}
    />
  );
};

export default TripPaymentModal;
