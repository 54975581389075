import React, { useEffect, useState } from "react";
import {
  RenderSVG,
  TripTavaWithBrand,
  PaidSuccessfully,
  ArrowLeft,
  DownloadIcon,
} from "../../../assets/icons";
import { useSelector } from "react-redux";
import { selectSelectedTrip } from "../../Booking/Trips";
import { get, isEmpty } from "lodash";
import {
  DEFAULT_VALUES,
  GST_RATE,
  NOT_AVAILABLE,
  PAYMENT_PROVIDERS,
  PLATFORM_FEE,
  PLATFORM_FEE_GST,
} from "../../../constants";
import {
  getCityNameByIata,
  getFormattedDate,
  getFormattedDateObject,
  getFormattedLongDate,
  getFutureFormattedDateObject,
  priceFormatter,
} from "../../../helper";
import { selectCountryInfo, selectExchangeRates } from "../../Profile";
import classNames from "classnames";
import { selectBookCallbackResponse } from "../Trips/trips.selector";
import {
  getTotalFlightPriceForTrip,
  getTotalHotelPriceForTrip,
} from "../../../helper/getTotalBookingAmountForTrip";
import { formatPriceForCurrency } from "../../../helper/priceFormatter";

const { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, ZERO, ONE } = DEFAULT_VALUES;
const { RAZORPAY } = PAYMENT_PROVIDERS;
const COMPANY_NAME = "Innostax Travel Technologies Pvt Ltd";
const COMPANY_ADDRESS = (
  <>
    Unit 446 - 447, JMD Megapolis, <br />
    Sector 48, Sohna Road, Gurgaon 122018
  </>
);

const REGISTERED_ADDRESS = (
  <>
    VB-19, TATA PRIMANTI <br />
    Badshahpur, Gurugram Haryana 122101
  </>
);

const HotelAndFlightInvoice = () => {
  const tripDetails = useSelector(selectSelectedTrip);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const bookCallbackResponse = useSelector(selectBookCallbackResponse);
  const exchangeRate = useSelector(selectExchangeRates);
  const [PNRList, setPNRList] = useState(EMPTY_ARRAY);
  const [shouldShowGatewayCharges, setShouldShowGatewayCharges] = useState(false);
  const [totalFlightAmount, setTotalFlightAmount] = useState(ZERO);
  const [totalHotelAmount, setTotalHotelAmount] = useState(ZERO);
  const currencyCode = selectedCountryInfo?.currency?.code;
  const currencySymbol = selectedCountryInfo?.currency?.symbol;
  const selectedHotelDetail = tripDetails?.hotels[0];
  const selectedFlightDetail = tripDetails?.flights;
  const hotelBlockRoomResult = get(
    selectedHotelDetail,
    "blockRoomResJson.blockRoomResult",
    EMPTY_ARRAY
  );
  const { hotelName } = hotelBlockRoomResult;
  const booking = get(selectedHotelDetail, "bookingReqJson", EMPTY_ARRAY);
  const hotelRoomInfo = get(
    hotelBlockRoomResult,
    "hotelRoomsDetails[0]",
    EMPTY_OBJECT
  );
  const dayRates = get(hotelRoomInfo, "dayRates", EMPTY_ARRAY);
  const checkInDateObject = dayRates?.[ZERO];
  const checkOutDateObject = dayRates?.slice(-1)[ZERO];
  const checkInDate = getFormattedDateObject(checkInDateObject?.date);
  const checkOutDate = getFutureFormattedDateObject(checkOutDateObject?.date);

  const hotelRoomsDetails = get(booking, "hotelRoomsDetails", EMPTY_ARRAY);

  const totalTavamarkup = hotelRoomsDetails.reduce((total, room) => {
    return total + (room.price.tavaMarkup || ZERO);
  }, ZERO);

  const initialPrice = { roomPrice: ZERO, offeredPrice: ZERO };
  const { offeredPrice } = hotelRoomsDetails.reduce((acc, { price }) => {
    const roomPrice = price?.roomPrice || ZERO;
    const offeredPrice = price?.offeredPrice || ZERO;
    return {
      roomPrice: acc.roomPrice + roomPrice,
      offeredPrice: acc.offeredPrice + offeredPrice,
    };
  }, initialPrice);

  useEffect(() => {
    if (!tripDetails) return;
    setPNRList(selectedFlightDetail.map((each) => each.pNR));
  }, [tripDetails]);
  const itineraries = selectedFlightDetail?.reduce((itineraryAcc, flight) => {
    const itinerary = get(
      flight,
      "bookingJson.journeyDetails.0.itineraries",
      EMPTY_ARRAY
    );
    return [...itineraryAcc, ...itinerary];
  }, []);

  useEffect(() => {
    if (!tripDetails) return;
    const totalFlightAmount = getTotalFlightPriceForTrip(
      tripDetails,
      currencyCode,
      exchangeRate
    );
    setTotalFlightAmount(totalFlightAmount);
    const totalHotelAmount = getTotalHotelPriceForTrip(
      tripDetails,
      currencyCode,
      exchangeRate
    );
    setTotalHotelAmount(totalHotelAmount);
  }, [tripDetails, selectedCountryInfo]);

  const journeyDetails = selectedFlightDetail?.map((flightInfo) =>
    get(flightInfo, "bookingJson.journeyDetails.0")
  );

  const priceDetails = journeyDetails?.map((journey) =>
    get(journey, "price", EMPTY_OBJECT)
  );

  const priceBreakDown = priceDetails?.reduce(
    (acc, journey) => {
      acc.totalFare += journey.grandTotal;
      acc.totalBaseFare += journey.offeredFare + (journey.tavaMarkup ?? 0);
      acc.totalTax += journey.taxFare;
      acc.totalOtherServices += journey.otherServicesAmount;
      return acc;
    },
    { totalFare: 0, totalBaseFare: 0, totalTax: 0, totalOtherServices: 0 }
  );

  const {
    totalBaseFare = 0,
    totalTax = 0,
    totalOtherServices = 0,
  } = priceBreakDown || {};

  const paymentDetails = get(
    journeyDetails,
    "0.price.paymentsDetails",
    EMPTY_OBJECT
  );

  const {
    offeredPriceInfo = EMPTY_OBJECT,
    otherServices = ZERO,
    platformFee = ONE,
  } = paymentDetails;

  const { baseFare = 0, airlineTaxes = 0 } = offeredPriceInfo;

  const totalAmount = (totalFlightAmount || 0 ) +
  (totalHotelAmount || 0)+
  PLATFORM_FEE + PLATFORM_FEE_GST;

  const otherChargesBase = totalAmount * 0.02;
  const gstOnOtherCharges = otherChargesBase * 0.18;
  const totalOtherCharges = (gstOnOtherCharges + otherChargesBase).toFixed(2);

  useEffect(() => {
    let payments = get(bookCallbackResponse, "payments", []);
    if (!bookCallbackResponse && tripDetails)
      payments = get(tripDetails, "payments", []);

    const lastTransaction = payments.at(-1);
    const isGatewayTransaction =
      get(lastTransaction, "paymentProvider.name", EMPTY_STRING) === RAZORPAY;
    setShouldShowGatewayCharges(isGatewayTransaction);
  }, [bookCallbackResponse, tripDetails]);

  const otherCharges = shouldShowGatewayCharges
    ? (Number(totalOtherCharges) + (PLATFORM_FEE || 0)).toFixed(2)
    : (PLATFORM_FEE || 0);

  return (
    <div>
      <div className='bg-white p-6 rounded-lg shadow-md w-full'>
        <div className='flex justify-end pb-4 hide-when-printing'>
          <button
            className='flex gap-2 items-center text-sm font-medium btn btn-primary'
            onClick={() => window.print()}
          >
            <RenderSVG
              Svg={DownloadIcon}
              alt='ticket-icon'
              className='w-[20px] h-[20px]'
              fill='currentColor'
              color='white'
            />
            <span>Print Invoice</span>
          </button>
        </div>
        <div className='border-gray-400 border-4 p-6 w-full'>
          <span className='block h-1 bg-[linear-gradient(320deg,_#ddceff,_#dbecff)] my-3'></span>

          <div className='flex justify-between items-start'>
            <div>
              <h4 className='text-4xl font-bold text-primary-700'>Invoice</h4>
              <p className='text-md text-gray-700 mb-2'>
                (This is not a valid travel document)
              </p>
              <div className='bg-[linear-gradient(320deg,_#ddceff,_#dbecff)] rounded-[10px_67px_10px_10px] p-6 shadow-md text-gray-700'>
                <div className='grid grid-cols-2 gap-x-6'>
                  <div className='flex items-center'>
                    <span className='font-medium text-lg text-gray-900'>
                      Invoice No:
                    </span>
                  </div>
                  <div className='flex justify-end items-center'>
                    <span className='text-lg'>
                      {tripDetails?.invoices?.length >= ONE &&
                      tripDetails?.invoices[ZERO]?.id
                        ? tripDetails?.invoices[ZERO]?.id
                        : NOT_AVAILABLE}
                    </span>
                  </div>
                  <div className='flex items-center'>
                    <span className='font-medium text-lg text-gray-900'>
                      Booking Id:
                    </span>
                  </div>
                  <div className='flex justify-end items-center'>
                    <span className='text-lg'>{tripDetails?.id}</span>
                  </div>

                  <div className='flex items-center'>
                    <span className='font-medium text-lg text-gray-900'>
                      Document Type:
                    </span>
                  </div>
                  <div className='flex justify-end items-center'>
                    <span className='text-lg'>Invoice</span>
                  </div>

                  <div className='flex items-center'>
                    <span className='font-medium text-lg text-gray-900'>
                      Invoice Date:
                    </span>
                  </div>
                  <div className='flex justify-end items-center'>
                    <span className='text-lg'>
                      {tripDetails?.invoices?.length >= ONE &&
                        getFormattedDate(
                          tripDetails?.invoices[ZERO]?.invoiceDate
                        )}
                    </span>
                  </div>

                  <div className='flex items-center'>
                    <span className='font-medium text-lg text-gray-900'>
                      Place of Supply:
                    </span>
                  </div>
                  <div className='flex justify-end items-center'>
                    <span className='text-lg'>
                      {tripDetails?.invoices?.length >= ONE &&
                        tripDetails?.invoices[ZERO]?.placeOfSupply}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-4 text-right'>
              <a href='#'>
                <RenderSVG
                  Svg={TripTavaWithBrand}
                  alt='tava-logo'
                  width='150'
                  height='48'
                  className='mx-auto'
                />
              </a>
            </div>
          </div>

          <span className='block h-1 bg-[linear-gradient(320deg,_#ddceff,_#dbecff)] my-3'></span>
          {!isEmpty(selectedHotelDetail) && (
            <div className='flex items-center mb-4'>
              <div className='flex flex-col gap-1 w-full'>
                <div className='bg-white rounded-lg shadow-sm border'>
                  <div className='flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between'>
                    <div className='flex-1'>
                      <h4 className='text-contrast-900 font-bold text-base mb-2'>
                        {hotelName}
                      </h4>
                      <div className='text-sm'>
                        <strong>Check-in:</strong> {checkInDate.day}{" "}
                        <strong>
                          {`${checkInDate.date} ${checkInDate.month} `}
                        </strong>
                        {checkInDate.year}
                      </div>{" "}
                      <div className='text-sm'>
                        <strong>Check-out:</strong> {checkOutDate.day}{" "}
                        <strong>
                          {`${checkOutDate.date} ${checkOutDate.month} `}
                        </strong>
                        {checkOutDate.year}
                      </div>
                    </div>
                  </div>
                  <div className='p-4 bg-primary-100/30'>
                    {!isEmpty(selectedHotelDetail) && (
                      <div className='overflow-x-auto mt-2'>
                        <table className='min-w-full divide-y divide-gray-200'>
                          <tbody>
                            <tr className='text-md'>
                              <td className='py-2 px-4 text-left font-bold'>
                                Total Hotel Charges
                              </td>
                              <td className='py-2 px-4 text-right font-bold'>
                                {formatPriceForCurrency(totalHotelAmount, currencyCode)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isEmpty(selectedFlightDetail) && (
            <div className='flex items-center mb-4'>
              <div className='w-full'>
                <div className='bg-white rounded-lg shadow-sm border'>
                  <div className='w-full px-4 py-3'>
                    <div className='flex justify-between'>
                      <p className='text-contrast-500 text-xs flex flex-col w-full gap-4'>
                        {itineraries.map((itinerary, index) => (
                          <div
                            className={classNames("flex flex-col", {
                              "": index,
                              "bg-red-100/30":
                                index < PNRList?.length && !PNRList?.[index],
                            })}
                          >
                            <div className='w-full'>
                              <div className=''>
                                <div className='flex gap-3 w-full'>
                                  <div className='flex gap-6 justify-between'>
                                    <div className=''>
                                      <h5 className='text-sm font-bold text-contrast-900 mb-1'>
                                        {getCityNameByIata(
                                          get(
                                            itinerary.segments,
                                            "0.departure",
                                            EMPTY_OBJECT
                                          ).iataCode
                                        )}{" "}
                                        (
                                        {
                                          get(
                                            itinerary.segments,
                                            "0.departure",
                                            EMPTY_OBJECT
                                          ).iataCode
                                        }
                                        )
                                      </h5>
                                    </div>
                                    <div className='icon shrink-0 opacity-50'>
                                      <RenderSVG
                                        Svg={ArrowLeft}
                                        width='20'
                                        stroke='#000000'
                                      />
                                    </div>
                                    <div className=''>
                                      <h5 className='text-sm font-bold text-contrast-900 mb-1'>
                                        {getCityNameByIata(
                                          get(
                                            itinerary.segments,
                                            `${
                                              itinerary.segments.length - ONE
                                            }.arrival`,
                                            EMPTY_OBJECT
                                          ).iataCode
                                        )}{" "}
                                        (
                                        {
                                          get(
                                            itinerary.segments,
                                            `${
                                              itinerary.segments.length - ONE
                                            }.arrival`,
                                            EMPTY_OBJECT
                                          ).iataCode
                                        }
                                        )
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className='w-full text-start text-sm text-black'>
                              {" "}
                              <span className='font-bold mr-2'>
                                <strong> Departure Date:</strong>
                              </span>
                              {getFormattedLongDate(
                                get(
                                  itinerary.segments,
                                  "0.departure",
                                  EMPTY_OBJECT
                                ).date,
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}
                            </p>
                          </div>
                        ))}
                      </p>
                    </div>
                  </div>
                  <div className='overflow-x-auto border-t border-dashed border-contrast-300 bg-primary-100/30 p-4'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <tbody>
                        <tr className='text-md'>
                          <td className='py-2 px-4 text-left font-bold'>
                            Total Flight Charges
                          </td>
                          <td className='py-2 px-4 text-right font-bold'>
                            {formatPriceForCurrency(totalFlightAmount, currencyCode)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='border-y-2 border-gray-400 mb-6 flex items-start justify-between'>
            <div className='mb-6 flex items-center'>
              <div className='logo3'>
                <RenderSVG
                  Svg={PaidSuccessfully}
                  alt='paid successfully'
                  width='80'
                  height='80'
                  className='mx-auto mt-2'
                />
              </div>
            </div>

            <div className='text-right'>
              <table className='min-w-full'>
                <tbody>
                  <tr className='font-bold text-lg text-contrast-700'>
                    <td className='py-2 px-4 text-left'>Other Charges:</td>
                    <td className='py-2 px-4 text-right'>
                      {currencySymbol}&nbsp;
                      {otherCharges}
                    </td>
                  </tr>
                  <tr className='font-bold text-lg text-contrast-700'>
                    <td className='py-2 px-4 text-left'>GST:</td>
                    <td className='py-2 px-4 text-right'>
                      {currencySymbol}&nbsp;
                      {GST_RATE}
                    </td>
                  </tr>

                  <tr className='font-bold text-lg text-primary-700'>
                    <td className='py-2 px-4 text-left'>Total Amount:</td>
                    <td className='py-2 px-4 text-right'>
                      {currencySymbol}&nbsp;
                      {priceFormatter(
                        totalFlightAmount +
                          totalHotelAmount +
                          Number(otherCharges) +
                          0.18
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='mb-4 border-b-2 border-gray-400 px-4 pb-4 flex justify-center text-gray-600'>
            <p className='text-sm'>
              This is a computer generated Invoice and does not require
              Signature/Stamp
            </p>
          </div>

          <div>
            <p className='font-bold mb-4'>Invoice issued by {COMPANY_NAME}</p>
            <div className='grid grid-cols-3 gap-4 text-sm'>
              <div className='flex flex-col gap-2'>
                <div className='font-semibold'>PAN Number</div>
                <div>AAHCI2822E</div>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='font-semibold'>HSN/SAC</div>
                <div>
                  {isEmpty(selectedFlightDetail) && isEmpty(selectedHotelDetail)
                    ? "No HSN/SAC available"
                    : isEmpty(selectedFlightDetail)
                    ? "998551(Reservation of hotel booking)"
                    : isEmpty(selectedHotelDetail)
                    ? "886223(Reservation services for air transportation)"
                    : "886223(Reservation services for air transportation), 998551(Reservation of hotel booking)"}
                </div>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='font-semibold'>Service Description</div>
                <div>
                  {isEmpty(selectedFlightDetail) && isEmpty(selectedHotelDetail)
                    ? "No booking details available"
                    : isEmpty(selectedFlightDetail)
                    ? "Reservation for Hotel Booking"
                    : isEmpty(selectedHotelDetail)
                    ? "Reservation for Flight Booking"
                    : "Reservation for Hotel and Flight"}
                </div>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='font-semibold'>CIN</div>
                <div>U79110HR2023PTC114461</div>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='font-semibold'>{COMPANY_NAME}</div>
                <div>{COMPANY_ADDRESS}</div>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='font-semibold'>REGISTERED OFFICE</div>
                <div>{REGISTERED_ADDRESS}</div>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='font-semibold'>GSTN</div>
                <div>06AAHCI2822E1ZX</div>
              </div>
            </div>
          </div>

          <div className='text-center text-lg font-semibold mt-6 bg-gray-100 py-2 px-4'>
            Thanks for your Business
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelAndFlightInvoice;
