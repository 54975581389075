import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { RenderSVG, UserCircle, AddImageIcon, InfoIcon } from "../../assets/icons";
import { InputField } from "../../components/molecules";
import UploadFile from "../../components/organisms/AppModals/UploadFile/UploadFile";
import { selectUserInfo, setUserInfo } from "./index";
import { DEFAULT_VALUES, REGEX } from "../../constants";
import { updateUser } from "../Users/users.actions";
import { getTravelers } from "./profile.actions";
import { selectCurrentUserInfo } from "../Auth";
import {
  getPanValidationSchema,
  getFormattedDateTime,
  getMinDateForDOB,
  renderFieldError,
  getCustomPassportValidationSchema
} from "../../helper";
import RenderPassportFields from "../HotelReview/RenderPassportFields";
import DropdownSelectField from "../../components/atoms/DropdownSelectField";
import Asterisk from "../../components/atoms/Asterisk";
import { Country } from "country-state-city";
import { PhoneNumberInput } from "../../components/atoms/PhoneNumberInput";
import { useTranslation } from "react-i18next";
import DateSearch from "../../components/atoms/DateSearch";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import StateDropdown from "../../components/atoms/StateDropdown";
import get from "lodash/get";
import { MODALS } from "../../components/organisms/AppModals";
import { setSelectedModal } from "../../components/organisms/Modal";
import { Tooltip } from "react-tooltip";
const { ZERO, ONE, SINGLE_SPACE_STRING, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;
const { PHONE_NUMBER } = REGEX;
const { MANAGE_PASSWORD_MODAL } = MODALS;
const EIGHTEEN = 18;

const countryList = Country.getAllCountries();
const { UPDATE_USERS, FETCH_USER } = SPINNER_NAMES;

const passportValidationSchema = getCustomPassportValidationSchema()

const panValidationSchema = getPanValidationSchema();

const PROFILE_DEFAULT_VALUE = {
  profilePic: null,
  name: EMPTY_STRING,
  email: "hello@gmai.com",
  gender: "Male",
  phone: EMPTY_STRING,
  state: EMPTY_STRING,
  country: {
    name: EMPTY_STRING,
  },
  dateOfBirth: getMinDateForDOB(EIGHTEEN),
  passportDetails: {
    passportNumber: EMPTY_STRING,
    issueCountry: { isoCode: EMPTY_STRING },
    issuedDate: EMPTY_STRING,
    expiryDate: EMPTY_STRING,
  },
  panCardDetails: {
    surname:EMPTY_STRING,
    panCardNumber: EMPTY_STRING,
  },
};

const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("Enter your first name")
    .matches(/^[a-zA-Z\s]+$/, "First name must contain letters only")
    .min(3, "First name must be at least 3 letters long"),
  middleName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s]*$/,"Middle name cannot contain number or any special character"),
  lastName: Yup.string()
    .trim()
    .required("Enter your last name")
    .matches(/^[a-zA-Z\s]+$/, "Last name must contain letters only")
    .min(3, "Last name must be at least 3 letters long"),
  city: Yup.string()
    .trim()
    .required("Enter your city")
    .matches(/^[a-zA-Z ]*[a-zA-Z][a-zA-Z ]*$/, "City name must contain letters only"),
  address: Yup.string()
    .trim()
    .required("Enter your address")
    .matches(/[a-zA-Z]/, "Must have at least one alphabet character"),
  phone: Yup.string()
    .trim()
    .matches(PHONE_NUMBER, "Please enter valid phone number")
    .required("Phone No. is required"),
  state: Yup.string()
    .trim()
    .required("State is required")
    .matches(/^[a-zA-Z ]*[a-zA-Z][a-zA-Z ]*$/, "State name must contain letters only"),
  //  dateOfBirth: Yup.date()
  //  .min(18, 'User must be at least 18 years old')
  //  .required('Date of Birth is required'),
  country: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Country Name is required")
      .matches(/^[a-zA-Z ]*[a-zA-Z][a-zA-Z ]*$/, "Country name must contain letters only"),
  }),
  ...passportValidationSchema,
  ...panValidationSchema.fields,
});

const PersonalInfoSection = ({ canUpdatePassword }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const selectedCurrentUserInfo = useSelector(selectCurrentUserInfo);

  const [initialValues, setInitialValues] = useState(PROFILE_DEFAULT_VALUE);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [modalParentDivClasses, setModalParentDivClasses] = useState(false);
  const [shouldShowSaveButton, setShouldShowSaveButton] = useState(true);
  const [isActiveSpinner, setIsActiveSpinner] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!userInfo) return;
    const passportIssueCountryDetails = countryList.find(
      (country) => country.name === userInfo.passportIssueCountry
    );
    const fullName = userInfo?.name?.split(SINGLE_SPACE_STRING) || [];
    const updatedInitialValues = {
      ...initialValues,
      name: userInfo.name?.trim() || EMPTY_STRING,
      firstName: userInfo.firstName?.trim() || fullName[ZERO]?.trim() || EMPTY_STRING,
      middleName: userInfo.middleName?.trim() || EMPTY_STRING,
      lastName: userInfo.lastName?.trim() || fullName[ONE]?.trim() || EMPTY_STRING,
      email: userInfo.email?.trim() || EMPTY_STRING,
      address: userInfo.address?.trim() || EMPTY_STRING,
      city: userInfo.city?.trim() || EMPTY_STRING,
      country: {
        ...initialValues.country,
        name: userInfo.country || EMPTY_STRING,
        isoCode: passportIssueCountryDetails?.isoCode || EMPTY_STRING,
      },
      state: userInfo.state || EMPTY_STRING,
      dateOfBirth: userInfo?.dateOfBirth || getMinDateForDOB(EIGHTEEN),
      phone: userInfo.phone || EMPTY_STRING,
      profilePic: userInfo.profilePic,
      gender: userInfo.gender || EMPTY_STRING,
      passportDetails: {
        ...initialValues.passportDetails,
        passportNumber: userInfo.passportNumber || EMPTY_STRING,
        issueCountry: passportIssueCountryDetails || { isoCode: EMPTY_STRING },
        issuedDate:
          userInfo.passportIssuanceDate || EMPTY_STRING,
        expiryDate:
          userInfo.passportExpiryDate || EMPTY_STRING
      },
      panCardDetails: {
        ...initialValues.panCardDetails,
        panCardNumber: userInfo.panNumber || EMPTY_STRING,
        surname:userInfo.lastName?.trim() || fullName[ONE]?.trim() || EMPTY_STRING
      },
    };
    setInitialValues(updatedInitialValues);
  }, [userInfo]);

  const handleUpdateProfilePhoto = (image, setFieldValue) => {
    setFieldValue("profilePic", image);
  };

  const handleOpenFileUploadModal = () => {
    setTimeout(() => setModalParentDivClasses("show"), 100);
    setShowUploadFileModal(true);
    document.body.style.overflow = "hidden";
  };

  const handleSaveProfile = (values) => {
    const userData = {
      PassportIssueCountry: userInfo.passportIssueCountry,
      gender: userInfo.gender,
      name: userInfo.name,
      firstName: userInfo.firstName,
      middleName: userInfo.middleName,
      lastName: userInfo.lastName,
      address: userInfo.address,
      city: userInfo.city,
      state: userInfo.state,
      country: userInfo.country,
      phone: userInfo.phone,
      dateOfBirth: userInfo.dateOfBirth,
      panNumber: userInfo.panNumber,
      passportExpiryDate: userInfo.passportExpiryDate,
      passportIssuanceDate: userInfo.passportIssuanceDate,
      passportNumber: userInfo.passportNumber?.trim(),
      profilePic: userInfo.profilePic,
    };
    const {
      profilePic,
      name,
      gender,
      firstName = EMPTY_STRING,
      middleName = EMPTY_STRING,
      lastName = EMPTY_STRING,
      address = EMPTY_STRING,
      city = EMPTY_STRING,
      state = EMPTY_STRING,
      country = EMPTY_STRING,
      panCardDetails,
      passportDetails,
      phone,
      dateOfBirth,
    } = values;
    const { panCardNumber = EMPTY_STRING } = panCardDetails || EMPTY_OBJECT;
    const { passportNumber = EMPTY_STRING, issueCountry = EMPTY_STRING, issuedDate, expiryDate } =
      passportDetails || EMPTY_OBJECT;
    const trimmedPassportNumber = passportNumber?.trim();
    const trimmedFirstName = firstName?.trim();
    const trimmedMiddleName = middleName?.trim();
    const trimmedLastName = lastName?.trim();
    const trimmedPanCardNumber = panCardNumber?.trim();
    const trimmedCity = city?.trim();
    const trimmedAddress = address?.trim()
    const req = {
      name:
        trimmedFirstName +
        (trimmedMiddleName && SINGLE_SPACE_STRING + trimmedMiddleName) +
        (trimmedLastName && SINGLE_SPACE_STRING + trimmedLastName),
      ...(profilePic !== userInfo?.profilePic && { profilePic }),
      gender,
      passportNumber: trimmedPassportNumber,
      PassportIssueCountry: issueCountry.name,
      passportIssuanceDate: issuedDate,
      passportExpiryDate: expiryDate,
      panNumber: trimmedPanCardNumber,
      phone: phone,
      dateOfBirth: dateOfBirth,
      firstName: trimmedFirstName,
      lastName: trimmedLastName,
      middleName: trimmedMiddleName,
      city: trimmedCity,
      state,
      address: trimmedAddress,
      country: country.name,
    };
    const modifiedDetail = compareObjects(userData, req);
    const { id } = selectedCurrentUserInfo;
    dispatch(updateUser({ id: userInfo?.id, body: modifiedDetail })).then(
      (res) => {
        if (!res?.payload) return;
        dispatch(getTravelers(id));
        toast.success("Changes Saved!");
      }
    );
  };

  const compareObjects = (obj1, obj2) => {
    const modifiedDetail = {};
    
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        modifiedDetail[key] = obj2[key];
      }
    });
  
    Object.keys(obj2).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        modifiedDetail[key] = obj2[key];
      }
    });
  
    return modifiedDetail;
  };

  const handleChangeLastName = (e,setFieldValue)=> {
    const lastNameValue = e.target.value;
    setFieldValue('lastName', lastNameValue);
    setFieldValue('panCardDetails.surname', lastNameValue);
  };
  
  return (
    <div className='w-full bg-white rounded-2xl min-h-auto divide-y-2 divide-tertiary-200'>
      <div className='flex justify-between px-4 pt-2'>
        <div className='text-lg leading-6 font-semibold'>
          Personal Information
        </div>
        {canUpdatePassword && (
          <button
            onClick={() => dispatch(setSelectedModal(MANAGE_PASSWORD_MODAL))}
            className='text-sm p-2 cursor-pointer text-primary-500 hover:underline hover:text-primary-800'
          >
            Manage Password
          </button>
        )}
      </div>
      <Spinner name={FETCH_USER} spinnerClassName='py-[39vh]'>
        <Formik
          initialValues={initialValues}
          validationSchema={profileValidationSchema}
          enableReinitialize
          onSubmit={(value) => handleSaveProfile(value)}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            dirty,
            resetForm,
            errors,
            touched,
            validateForm,
          }) => (
            <Form className='flex flex-col gap-3'>
              <div className='grid grid-cols-4 px-5 py-3'>
                <div className='lg:col-span-1 col-span-4 flex flex-col justify-center items-center self-center p-2'>
                  <div className='group relative w-40 h-40 rounded-full overflow-hidden'>
                    {values.profilePic ? (
                      <img src={values.profilePic} className='w-full h-full' />
                    ) : (
                      <RenderSVG
                        Svg={UserCircle}
                        className='text-contrast-500 w-full h-full'
                      />
                    )}
                    <div
                      className='invisible group-hover:visible bg-black bg-opacity-60 flex flex-col items-center justify-center gap-3 absolute inset-0 text-center cursor-pointer'
                      onClick={handleOpenFileUploadModal}
                    >
                      <RenderSVG
                        Svg={AddImageIcon}
                        className='text-white fill-none'
                        width='36'
                        height='36'
                      />
                      <div className='text-white text-xs'>Update Photo</div>
                    </div>
                  </div>
                </div>
                <div className='lg:col-span-3 col-span-4'>
                  <div className='grid grid-cols-12 gap-4 mb-2'>
                    <div className='col-span-12 sm:col-span-4'>
                      <InputField
                        id='firstName'
                        name='firstName'
                        type='text'
                        label='First Name'
                        showAsterisk={true}
                        placeholder='First name'
                        divMarginBottom='4'
                        inputFieldClasses='p-[6px]'
                      />
                    </div>
                    <div className='col-span-12 sm:col-span-4'>
                      <InputField
                        id='middleName'
                        name='middleName'
                        type='text'
                        placeholder='Middle name'
                        label='Middle Name'
                        divMarginBottom='4'
                        inputFieldClasses='p-[6px]'
                      />
                    </div>
                    <div className='col-span-12 sm:col-span-4'>
                      <InputField
                        id='lastName'
                        name='lastName'
                        type='text'
                        label='Last Name'
                        showAsterisk={true}
                        placeholder='Last name'
                        divMarginBottom='4'
                        onChange={(e)=>{handleChangeLastName(e,setFieldValue)}}
                        inputFieldClasses='p-[6px]'
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 gap-4'>
                    <div className='lg:col-span-3 col-span-12'>
                      <label
                        htmlFor='phone'
                        className='block text-sm font-medium mb-1 text-contrast-900'
                      >
                        Phone Number
                        <Asterisk />
                      </label>
                      <PhoneNumberInput
                        id='phone'
                        name='phone'
                        values={values}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        showFormattedNumber={true}
                        placeholder='Enter Phone number'
                        t={t}
                      />
                      <div>{renderFieldError("phone", errors, touched)}</div>
                    </div>
                    <div className='lg:col-span-4 col-span-12'>
                      <InputField
                        id='email'
                        name='email'
                        type='text'
                        label='Email'
                        disabled
                        divMarginBottom='4'
                        inputFieldClasses='p-[6px]'
                      />
                    </div>
                    <div className='lg:col-span-3 col-span-12'>
                      <label
                        htmlFor='issueDate'
                        className='text-sm font-medium mb-1 text-contrast-900 flex items-center'
                      >
                        Date of Birth
                        <div
                          className='w-5 h-5 ml-2 relative'
                          data-tooltip-id='dob-tooltip'
                          data-tooltip-place='top'
                          data-tooltip-content='User must be 18 years and above'
                        >
                          <RenderSVG
                            Svg={InfoIcon}
                            className='relative min-w-[20px] cursor-pointer'
                            alt='info-icon'
                          />
                          <Tooltip
                            id='dob-tooltip'
                            className='!w-56 !sm:w-72 !bg-primary-900 !rounded-lg !z-50'
                          />
                        </div>
                      </label>
                      <div>
                        <DateSearch
                          id='issueDate'
                          name='dateOfBirth'
                          noOfMonth={1}
                          showCalendarIcon={true}
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                          values={values}
                          minDate={""}
                          maxDate={getMinDateForDOB(EIGHTEEN)}
                          position='top-right'
                        />
                      </div>
                    </div>
                    <div className='lg:col-span-2 col-span-12'>
                      <label
                        htmlFor='gender'
                        className='block text-sm font-medium mb-1 text-contrast-900'
                      >
                        Gender
                      </label>
                      <select
                        id='gender'
                        name='gender'
                        className='form-control block w-full h-[38px] text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                        value={values.gender}
                        onChange={(e) =>
                          setFieldValue("gender", e.target.value)
                        }
                      >
                        <option value='MALE'>Male</option>
                        <option value='FEMALE'>Female</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col px-5'>
                <div className='grid grid-cols-12 gap-4 mb-2'>
                  <RenderPassportFields colSpan='3' />
                </div>
                <div className='grid grid-cols-12 gap-4 mb-2'>
                  <div className='col-span-12 lg:col-span-3'>
                    <InputField
                      id='pandCardNumber'
                      name='panCardDetails.panCardNumber'
                      type='text'
                      placeholder='PAN number'
                      label='PAN Card Number'
                      showAsterisk={true}
                      divMarginBottom='4'
                      inputFieldClasses='p-[6px]'
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 gap-4'>
                  <div className='col-span-12 lg:col-span-3'>
                    <label
                      htmlFor='country'
                      className='block text-sm font-medium mb-1 text-contrast-900'
                    >
                      Country
                      <Asterisk />
                    </label>
                    <DropdownSelectField
                      name='country'
                      value={values.country}
                      onChange={() => {
                        !get(errors, "state", false) &&
                          setFieldTouched("state", false);
                        setFieldValue("state", "");
                      }}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      selectableValues={countryList}
                      valueToShow='name'
                      validateForm={validateForm}
                      type='name'
                      placeholder='Enter your Country'
                    />
                    <div>
                      {renderFieldError("country.name", errors, touched)}
                    </div>
                  </div>
                  <div className='col-span-12 lg:col-span-3'>
                    <label className='block text-sm font-medium mb-1 text-contrast-900'>
                      State
                      <Asterisk />
                    </label>
                    {values.country.name === "India" ? (
                      <StateDropdown
                        onChange={(value) => setFieldValue("state", value)}
                        value={values.state}
                        fieldName='state'
                        setFieldTouched={setFieldTouched}
                        placeHolder='profilePage.placeholders.state'
                        t={t}
                      />
                    ) : (
                      <>
                        <Field
                          id='state'
                          name='state'
                          type='text'
                          label='State'
                          className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                          placeholder={t("profilePage.placeholders.state")}
                          onBlur={() => setFieldTouched("state", true)}
                        />
                        <div>{renderFieldError("state", errors, touched)}</div>
                      </>
                    )}
                  </div>
                  <div className='col-span-12 lg:col-span-3'>
                    <InputField
                      id='city'
                      name='city'
                      type='text'
                      label='City'
                      showAsterisk={true}
                      placeholder={"Enter your City"}
                      divMarginBottom='4'
                      inputFieldClasses='p-[6px]'
                    />
                  </div>
                  <div className='col-span-12 lg:col-span-3'>
                    <InputField
                      id='address'
                      name='address'
                      type='text'
                      label='Address'
                      showAsterisk={true}
                      placeholder={"Enter your address"}
                      divMarginBottom='4'
                      inputFieldClasses='p-[6px]'
                    />
                  </div>
                </div>
              </div>
              {shouldShowSaveButton && (
                <div className='w-full flex justify-end px-5 py-4 border-t-2 border-tertiary-300'>
                  {dirty && (
                    <button
                      type='reset'
                      className='text-black text-base px-4 py-2 rounded-md mx-2 border'
                      onClick={() => resetForm()}
                    >
                      Reset
                    </button>
                  )}
                  <button
                    type='submit'
                    className='disabled:cursor-not-allowed disabled:opacity-75 bg-primary-600 text-white text-base px-4 py-2 rounded-md mx-2'
                    disabled={!dirty}
                  >
                    <Spinner
                      name={UPDATE_USERS}
                      setIsActiveSpinner={setIsActiveSpinner}
                      size='w-24 h-6'
                    >
                      Save Changes
                    </Spinner>
                  </button>
                </div>
              )}
              {showUploadFileModal && (
                <UploadFile
                  parentClasses={modalParentDivClasses}
                  handleClose={() => setShowUploadFileModal(false)}
                  handleSave={(image) =>
                    handleUpdateProfilePhoto(image, setFieldValue)
                  }
                />
              )}
            </Form>
          )}
        </Formik>
      </Spinner>
    </div>
  );
};

export default PersonalInfoSection;
