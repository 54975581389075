import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { KebabMenu, RenderSVG, SearchIcon, Trash, ClockExclamation, ExclamationCircleIcon } from "../../../assets/icons";
import {
  BOOK_NEW_TRIP_TABS,
  DEFAULT_VALUES,
  BOOKING_STATUS_CODES,
  CACHE_KEYS,
  SEARCH_SECTION,
} from "../../../constants";
import { actions as searchActions } from "../../../components/organisms/Search/search.reducer";
import SelectedFlightTrip from "./SelectedFlightTrip";
import SelectedHotelTrip from "./SelectedHotelTrip";
import {
  FlightSummaryModal,
  HotelSummaryModal,
} from "../../../components/organisms/AppModals/SummaryModal";
import { MODALS } from "../../../components/organisms/AppModals";
import { deleteChildByBookingId, selectSelectedTrip, selectShowTimerExpireBanner, setSelectedTrip } from "../Trips";
import { get, isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { selectCountryInfo } from "../../Profile";
import { resetTripStatus, selectTripOrder } from "../FlightBookings";
import { useClickOutside } from "../../../helper";
import {
  resetActiveFilters,
} from "../../../components/organisms/Search";
import ConfirmationModal from "../../../components/organisms/AppModals/ConfirmationModal";
import { getFromLocalStorage } from "../../../helper";
import { selectActiveSpinners, SPINNER_NAMES } from "../../../components/organisms/Spinner";

const { EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT, ZERO, ONE } = DEFAULT_VALUES;
const { resetFlights, resetHotelSearchResult } = searchActions;
const { FLIGHT, HOTEL } = BOOK_NEW_TRIP_TABS;
const { FLIGHT: FLIGHT_BOOKING, HOTEL: HOTEL_BOOKING } = SEARCH_SECTION;
const { SUMMARY_MODAL } = MODALS;
const { DELETE_CHILD_BOOKING_BY_PARENT_ID } = SPINNER_NAMES;
const { DRAFT } = BOOKING_STATUS_CODES;
const { TRIP_TIME_EXPIRY } = CACHE_KEYS;
const FLIGHT_SEARCH = "flights";
const HOTEL_SEARCH = "hotels";
const tripTimeExpiry = getFromLocalStorage(TRIP_TIME_EXPIRY);

const RenderTripCard = ({ service }) => {
  switch (service) {
    case FLIGHT:
      return <SelectedFlightTrip />;
    case HOTEL:
      return <SelectedHotelTrip />;
  }
};

const checkTenantStatus = (serviceData, header) => {
  if (header == HOTEL) {
    const tenantStatus = get(serviceData, "0.tenantBookingStatus", DRAFT);
    return tenantStatus === DRAFT;
  }
  if (header == FLIGHT) {
    const tenantStatus = serviceData.map(({ tenantStatus }) => tenantStatus);
    const uniqueStatusArray = new Set(tenantStatus);
    if ([...uniqueStatusArray].length === ONE)
      return [...uniqueStatusArray].includes(DRAFT);
    return [...uniqueStatusArray].length === ZERO;
  }
};

const TripCard = ({ header, icon }) => {
  const menuRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const activeSpinners = useSelector(selectActiveSpinners);
  const selectedTripOrder = useSelector(selectTripOrder);
  const selectTimerExpireBanner = useSelector(selectShowTimerExpireBanner);
  const isDeleteBookingByParentIdAPIActive = activeSpinners.includes(DELETE_CHILD_BOOKING_BY_PARENT_ID);

  const [serviceData, setServiceData] = useState(EMPTY_ARRAY);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleteBookingModalOpen, setIsDeleteBookingModalOpen] = useState(false);
  const selectedTripId = selectedTrip?.id;

  const { status = EMPTY_STRING } = selectedTrip || EMPTY_OBJECT;
  const currencySymbol = selectedCountryInfo?.currency?.symbol;

  const deleteBookingData = {
      type: 'Cancel',
      img: ExclamationCircleIcon,
      header: 'Delete Booking',
      button: 'Delete',
  }

  useEffect(() => {
    if (!selectedTrip) return;
    const { flights = EMPTY_ARRAY, hotels = EMPTY_ARRAY } = selectedTrip;
    if (header == FLIGHT) setServiceData(flights);
    if (header == HOTEL) setServiceData(hotels);
    if(selectedTripOrder.length > 0) {
      resetTripStatus();
      dispatch(resetActiveFilters());
      dispatch(resetHotelSearchResult());
    }
  }, [selectedTrip, header]);

  useClickOutside(menuRef, setIsDropdownOpen);

  const isTenantStatusPending = checkTenantStatus(serviceData, header);
  const itineraries = selectedTrip?.flights?.reduce((itineraryAcc, flight) => {
    const itinerary = get(
      flight,
      "bookingJson.journeyDetails[0].itineraries",
      []
    );
    return [...itineraryAcc, ...itinerary];
  }, []);
  const travelerData = get(
    selectedTrip,
    "flights[0].bookingJson.journeyDetails[0].travelerDetails",
    []
  );

  const [selectedModal, setSelectedModal] = useState(null);

  const [showTimeOutBanner, setShowTimeOutBanner] = useState({
    flight: false,
    hotel: false,
  });

  const handleClose = () => setSelectedModal(null);
  const handleSearch = (tab) => {
    switch (tab) {
      case FLIGHT: {
        dispatch(resetFlights());
        dispatch(resetTripStatus());
        dispatch(resetActiveFilters());
        return navigate(FLIGHT_SEARCH);
      }
      case HOTEL: {
        dispatch(resetHotelSearchResult());
        dispatch(resetTripStatus());
        return navigate(HOTEL_SEARCH);
      }
    }
  };

  const hotel = selectedTrip?.hotels[0];
  const hotelBlockRoomResult = get(
    hotel,
    "blockRoomResJson.blockRoomResult",
    EMPTY_ARRAY
  );
  const hotelRoomInfo = get(
    hotelBlockRoomResult,
    "hotelRoomsDetails[0]",
    EMPTY_OBJECT
  );
  const inclusions = get(hotelRoomInfo, "inclusion", EMPTY_ARRAY);
  const { providerBookingStatus } = hotel || EMPTY_STRING;

  const splittedInclusions = inclusions.flatMap((inc) => inc.split(","));

  const lastCancellationDate = get(
    hotelRoomInfo,
    "lastCancellationDate",
    EMPTY_STRING
  );

  const cancellationPolicy = hotel?.tenantBookingStatus !== "FAILED"  ? get(
    hotelRoomInfo,
    "cancellationPolicy",
    EMPTY_STRING
  ):"";

  const cancellationMsgArr = cancellationPolicy
    ?.split("#^#")
    ?.filter((e) => e?.includes("#!#"))
    ?.toString()
    ?.replaceAll("#!#", "")
    ?.split("|")
    ?.filter((item) => item && item !== "undefined")
    ?.map((msg) => (
      <li className='list-item' key={uuid()}>
        {msg}
      </li>
    ));
  const dayRates = get(hotelRoomInfo, "dayRates", EMPTY_ARRAY);
  const tavaBookingId = get(hotel, "tavaBookingId", EMPTY_STRING);

  const booking = get(hotel, "bookingReqJson", EMPTY_ARRAY);

  const hotelRoomsDetails = get(booking, "hotelRoomsDetails", EMPTY_ARRAY);
  const totalGuests = hotelRoomsDetails.reduce(
    (acc, { hotelPassenger }) => acc.concat(...hotelPassenger),
    []
  );

  const getTotalPrice = (hotelRoomsDetails) => {
    const initialPrice = { roomPrice: ZERO, offeredPrice: ZERO };
    const { roomPrice, offeredPrice } = hotelRoomsDetails.reduce(
      (acc, { price }) => {
        const roomPrice = price?.roomPrice || ZERO;
        const offeredPrice = price?.offeredPrice || ZERO;
        return {
          roomPrice: acc.roomPrice + roomPrice,
          offeredPrice: acc.offeredPrice + offeredPrice,
        };
      },
      initialPrice
    );
    return {
      totalRoomPrice: roomPrice,
      totalOfferedPrice: offeredPrice,
      totalTaxesAndFees: offeredPrice - roomPrice,
    };
  };

  const isVoucherBooking = get(hotel, "bookingReqJson.isVoucherBooking", false);
  const hotelImages = get(
    hotel,
    "HotelRoomInfo.HotelInfoResult.HotelDetails.Images"
  );

  const { addressLine1, addressLine2, hotelName } = hotelBlockRoomResult;

  const { starRating } = hotelBlockRoomResult || EMPTY_STRING;
  const { hotelPolicyDetail } = hotelBlockRoomResult || EMPTY_STRING;
  const { invoiceNo } = hotel || EMPTY_STRING;
  const { noOfRooms, hotelCode } = booking || EMPTY_STRING;
  const hotelInfo = {
    hotelBookingStatus: providerBookingStatus,
    addressLine1,
    addressLine2,
    hotelImages,
    hotelName,
    hotelCode,
    starRating,
    cancellationMsgArr,
    hotelPolicyDetail
  };

  const bookingInfo = {
    isVoucherBooking,
    lastCancellationDate,
    tavaBookingId,
    invoiceNo,
    dayRates: dayRates,
    noOfRooms,
    ...getTotalPrice(hotelRoomsDetails),
    currency: currencySymbol,
    splittedInclusions,
    checkIn: hotel?.blockRoomReqJson?.checkInDate?.replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$2-$1') || null,
    checkOut: hotel?.blockRoomReqJson?.checkOutDate?.replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$2-$1') || null,
    hotelPolicyDetail
  };

  const handlePreview = () => {
    navigate(`preview/${header.toLowerCase()}`);
  };

  useEffect(() => {
    const filterTimerBanner = selectTimerExpireBanner?.find(
      (e) => e?.id === selectedTripId
    )?.timeExpiryStatus;
    setShowTimeOutBanner({
      flight: filterTimerBanner?.flight,
      hotel: filterTimerBanner?.hotel,
    });
  }, [selectedTripId, tripTimeExpiry, selectTimerExpireBanner]);

  const handleClearBooking = () => {
    const bookingType = header === FLIGHT ? FLIGHT_SEARCH : HOTEL_SEARCH;

    if (!isEmpty(selectedTrip) && !isDeleteBookingByParentIdAPIActive) {
        dispatch(deleteChildByBookingId({
            id: selectedTripId,
            type: header === FLIGHT ? FLIGHT_BOOKING : HOTEL_BOOKING
        })).then((res) => {
            if (res.payload)
                dispatch(setSelectedTrip({
                    ...selectedTrip,
                    [bookingType]: EMPTY_ARRAY
                }));
            setIsDeleteBookingModalOpen(false)
        })
    }
  }

  return (
    <div className='rounded-lg border overflow-hidden border-contrast-200 bg-contrast-50 mb-4'>
      <div className='bg-white border-b border-contrast-200 px-4 py-2 flex items-center gap-3 justify-between'>
        <div className='flex gap-2 items-center'>
          <div className='icon w-6 h-6 grid place-content-center rounded-full bg-blue-100'>
            <RenderSVG Svg={icon} width='16' height='16' alt='trip_icon' />
          </div>
          <span className='text-contrast-900 text-lg font-semibold'>
            {header}
          </span>
        </div>
        {showTimeOutBanner.flight && header == FLIGHT && (
          <div className='flex gap-2 text-red-700'>
            Session Expired, Search Again
          </div>
        )}
        {showTimeOutBanner.hotel && header == HOTEL && (
          <div className='flex gap-2 text-red-700'>
            Session Expired, Search Again
          </div>
        )}
        <div className='flex gap-2'>
          {!!serviceData.length && (
            <button
              className='py-2 px-4 inline-flex items-center gap-2 text-center rounded-md bg-primary-100 hover:bg-primary-200 active:bg-primary-100 border-none shadow-sm text-sm text-primary-700 font-medium'
              onClick={() => {
                setSelectedModal(SUMMARY_MODAL);
              }}
            >
              View Summary
            </button>
          )}
          {status === DRAFT && (
            <div className='flex justify-between'>
              <button
                className='py-2 px-4 inline-flex items-center gap-2 text-center rounded-md bg-primary-100 hover:bg-primary-200 active:bg-primary-100 border-none shadow-sm text-sm text-primary-700 font-medium'
                onClick={() => handleSearch(header)}
              >
                <RenderSVG
                  Svg={SearchIcon}
                  className='text-blue'
                  alt='Search Icon'
                />{" "}
                {serviceData.length ? `Change ${header}s` : `Search ${header}s`}
              </button>
              {!!serviceData.length && (
                <div
                  onClick={() => setIsDeleteBookingModalOpen(true)}
                  className="icon w-10 h-10 grid cursor-pointer place-content-center rounded-md bg-primary-100 m-auto ml-2 hover:bg-primary-200"
                >
                  <RenderSVG
                    Svg={Trash}
                    width='25'
                    height='25'
                    alt='Delete'
                  />
                </div>
              )}
            </div>
          )}
          {!isTenantStatusPending && (
            <>
              <div
                className='w-[38px] h-[38px] p-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center gap-2 inline-flex relative'
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className='w-5 h-5'>
                  <RenderSVG
                    Svg={KebabMenu}
                    width='20'
                    height='20'
                    alt='kebab-menu'
                  />
                </div>
                {isDropdownOpen && (
                  <div
                    ref={menuRef}
                    className='inline-flex absolute z-10 top-[110%] right-0 rounded-lg shadow-2xl bg-white p-1 py-2 px-4  items-center gap-2 text-center hover:text-primary-700 border-none text-sm text-contrast-900 font-medium border border-gray-200'
                  >
                    <button onClick={handlePreview}>Preview </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className='p-6'>
        <RenderTripCard service={header} />
      </div>
      {selectedModal === SUMMARY_MODAL && (
        <>
          {header === HOTEL && (
            <HotelSummaryModal
              title='Hotel Summary'
              hotelInfo={hotelInfo}
              handleSubmit={() => {}}
              handleClose={handleClose}
              bookingInfo={bookingInfo}
              totalGuests={totalGuests}
              hotelRoomsDetails={hotelRoomsDetails}
            />
          )}
          {header === FLIGHT && (
            <FlightSummaryModal
              title='Flight Summary'
              itineraries={itineraries}
              handleSubmit={() => {}}
              handleClose={handleClose}
              flights={serviceData}
              travelerData={travelerData}
            />
          )}
        </>
      )}
      {isDeleteBookingModalOpen && (
        <ConfirmationModal
          isModalOpen={isDeleteBookingModalOpen}
          setIsModalOpen={setIsDeleteBookingModalOpen}
          data={deleteBookingData}
          handleAction={handleClearBooking}
        >
          <div className='flex flex-col gap-5 text-sm text-contrast-600'>
            Do you want to remove the saved {header} booking?
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};

export default TripCard;
