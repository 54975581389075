import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Tooltip } from "react-tooltip";
import {
  cloneDeep,
  get,
  isEmpty,
  isEqual,
  size,
  differenceWith,
  toPairs,
} from "lodash";
import HotelsMapModal from "../../components/organisms/AppModals/HotelsMapModal/HotelsMapModal";
import { resetHotelInfo, setHotelInfoReqBody } from "../HotelInfo";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  selectFilteredHotels,
  selectHotelSearchFilters,
  selectHotels,
  setFilteredHotelResult,
  selectHotelsSortingValues,
  setFilteredHotels,
  setHotelsActiveFilters,
  setHotelsSortingValues,
  selectHotelsActiveFilters,
  selectHotelsCount,
  setHotelsCount,
} from "../../components/organisms/Search";
import { selectCountryInfo } from "../Profile";
import NoHotelResults from "./NoHotelResults";
import HotelSearch from "../../components/organisms/Search/Hotel";
import FilterHotelsDropDown from "./HotelFilters/FilterHotelsDropDown";
import HotelLoader from "../../components/organisms/HotelsLoader";
import { selectTripStatus, setTripStatus, updateBookingStatus } from "../Booking/FlightBookings";
import { MODALS } from "../../components/organisms/AppModals";
import {
  setToSessionStorage,
  formatPriceWithCommas,
  getHotelImageUrl,
  generatePolicyViolationMessage,
  getObjectFromQueryParams,
  renderStars,
  generateHotelAddress,
  getFromSessionStorage,
  useClickOutside,
} from "../../helper";
import {
  CACHE_KEYS,
  DEFAULT_VALUES,
  INITIAL_HOTEL_VALUES,
  INITIAL_HOTEL_FILTERS,
  CURRENCY_SYMBOLS,
  HOTEL_RESULT_SORT_OPTIONS
} from "../../constants";
import { selectTenantDetails } from "../Auth";
import EmptyLayout from "../../components/molecules/EmptyLayout";
import { Filter, RenderSVG, InfoIcon, Check, ChevronDown } from "../../assets/icons";
import { setSelectedModal } from "../../components/organisms/Modal";
import { setSelectedHotelViewMore } from "../Booking/Trips";
import classNames from "classnames";
import { isEmptyObject } from "../../utils/validateObject";
import { decodeHtml } from "../../helper/hotelBookingHelpers";


const { HOTEL_INFO_REQUEST_BODY } = CACHE_KEYS;
const { FETCH_HOTEL_RESULTS, FETCH_HOTEL_STATIC_DATA } = SPINNER_NAMES;
const { ZERO, ONE, THREE, EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } =
  DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;

const HotelResultCard = ({ hotelInformation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedHotelResults = useSelector(selectHotels);
  const sourceData = get(selectedHotelResults, "sourceData", EMPTY_ARRAY);
  const filteredHotels = useSelector(selectFilteredHotels);

  const [imageError, setImageError] = useState(false);
  const [shouldShowHotelMapModal, setShouldShowHotelMapModal] = useState(false);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const { HOTEL_VIEW_MORE_MODAL } = MODALS;
  const {
    resultIndex,
    hotelCode,
    hotelName,
    hotelAddress = {},
    starRating,
    price: { otherCharges, tax, roomPrice, tavaMarkup, publishedPrice },
    hotelPicture,
    supplierHotelCodes,
    hotelPolicy,
    hotelImages,
    hotelAmenities = [],
    costPolicyViolation,
    ratingPolicyViolation,
    userRatings,
    hotelReviewCount,
    freeBreakfast,
    freeCancellation,
    payAtHotel,
    source,
    latitude,
    longitude,
  } = hotelInformation;
  const { checkInDate, checkOutDate } = searchFilters || {};
  const findTraceId = (sourceData, source) => {
    const data = sourceData.find((item) => item.source === source);
    return data ? data.traceId : null;
  };

  const traceId = findTraceId(sourceData, source);

  const displayedAmenities = hotelAmenities?.slice(0, 3);
  const [isExpanded, setExpanded] = useState(false);
  const totaltax = otherCharges + tax;
  const isPriceDifference = publishedPrice.toFixed(1) - (roomPrice + tavaMarkup + totaltax).toFixed(1)
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const categoryId = get(supplierHotelCodes, "[0].categoryId", EMPTY_STRING);
  const { sessionParams, sessionId } =
    getFromSessionStorage("hotelsSession") || EMPTY_OBJECT;

  const queryParams = `${"sessionId"}=${sessionParams}`;
  const handleSelectHotel = () => {
    dispatch(resetHotelInfo());

    const { ip: endUserIp } = selectedCountryInfo;
    const hotelInfoAPIReq = {
      resultIndex,
      hotelCode,
      categoryId,
      endUserIp,
      traceId,
      sessionId,
      checkInDate: checkInDate.replaceAll("/", "-"),
      checkOutDate: checkOutDate.replaceAll("/", "-"),
      source: "TBO", //TODO Need to eliminate use of specific provider name
    };
    dispatch(setFilteredHotelResult([hotelInformation]));
    dispatch(setHotelInfoReqBody(hotelInfoAPIReq));
    setToSessionStorage(HOTEL_INFO_REQUEST_BODY, hotelInfoAPIReq);
    const hotelResultURL = `${hotelCode}?${queryParams}`;
    navigate(hotelResultURL);
  };
  const addressLines = generateHotelAddress(hotelAddress);
  const hotelImage = getHotelImageUrl(hotelImages);
  const message = generatePolicyViolationMessage({
    costPolicyViolation,
    ratingPolicyViolation,
    trip: "Hotel",
  });

  const hotelsWithLocation = filteredHotels.filter(
    (hotel) => !isEmpty(hotel.latitude) && !isEmpty(hotel.longitude)
  );

  const handleMapModalClose = () => setShouldShowHotelMapModal(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setExpanded(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isExpanded]);
  }

  const showHotelAmenitiesButton = () => {
    dispatch(setSelectedModal(HOTEL_VIEW_MORE_MODAL));
    dispatch(
      setSelectedHotelViewMore({
        hotelAmenities,
        hotelImages,
        hotelPolicy,
        addressLines,
        hotelName,
      })
    );
  };

  const viewMoreHandler = () => {    
    return hotelAmenities?.length > ZERO ? hotelAmenities?.length : (!isEmptyObject(hotelImages) ? Object.keys(hotelImages)?.length : (!isEmptyObject(hotelPolicy) ? Object.keys(hotelPolicy)?.length : ZERO));
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const getFirstValidImageUrl = (hotelPicture) => {
    if (!hotelPicture) {
      return null;
    }
    const urls = hotelPicture.split(',').map(url => url.trim()).filter(url => url !== EMPTY_STRING);
    return urls[ZERO];
  };

  return (
    <div className='w-full mx-auto relative' ref={wrapperRef}>
      {freeBreakfast && (
        <div className='z-20 absolute top-[36px] -left-3 '>
          <div className='absolute w-[18px] h-[12px] skew-x-[45deg] rotate-45 -bottom-1 -left-0.5  bg-green-800' />
          <div className='relative bg-green-800 text-white text-xs text-center rounded-md rounded-bl-none p-1'>
            Breakfast included
          </div>
        </div>
      )}
      <div className='border rounded-lg bg-white mb-3 hover:shadow-lg transition duration-400 ease-in-out w-full overflow-hidden'>
        <div className='flex justify-between'>
          <div className='w-full'>
            {!isEmpty(message) && (
              <div className='top-0 left-0 flex gap-1 items-center w-1/2 right-0 bg-gradient-to-r from-red-500 to-transparent text-white px-2 text-xs font-bold py-1'>
                Violates Policy
                <div
                  data-tooltip-id={hotelCode}
                  data-tooltip-place='top'
                  data-tooltip-content={message}
                >
                  <Tooltip
                    id={hotelCode}
                    className='!w-56 !sm:w-72 !bg-primary-900 !rounded-lg !z-50'
                  />
                  <RenderSVG
                    Svg={InfoIcon}
                    width='16'
                    height='16'
                    alt='Info icon'
                  />
                </div>
              </div>
            )}
          </div>
          <div className='relative px-4 flex flex-col items-center justify-between text-end'>
            {userRatings && (
              <div className='absolute top-2 right-2'>
                <div className='w-8 py-2 text-white text-center bg-primary-600 rounded-md font-medium text-xs rounded-bl-none'>
                  {userRatings}
                </div>
                <div>
                  {hotelReviewCount && (
                    <span className='text-nowrap text-[10px]'>
                      ({formatPriceWithCommas(hotelReviewCount)} reviews)
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='p-4 pr-5 w-full grid grid-cols-1 sm:grid-cols-3 gap-4 min-h-60'>
          {imageError ? (
            <div className='flex justify-center items-center rounded-lg h-full w-full bg-black bg-opacity-30 px-4 py-2'>
              <span className='text-white font-bold'>{hotelName}</span>
            </div>
          ) : (
            <img
              src={hotelImage || getFirstValidImageUrl(hotelPicture)}
              alt={hotelName}
              onError={() => setImageError(true)}
              className={"rounded-md object-cover aspect-square"}
              height='232'
              width='252'
            />
          )}

          <div className='col-span-2 flex flex-col sm:flex-row justify-between'>
            <div className='flex flex-col justify-between overflow-wrap-anywhere'>
              <div>
                <div className='flex items-center justify-between'>
                  <div className='flex flex-col items-start'>
                    <h2 className='text-start text-lg font-bold text-primary-900'>
                      {decodeHtml(hotelName[ZERO]?.toUpperCase() + hotelName.slice(ONE))}{" "}
                    </h2>
                    {starRating > 0 && (
                      <div className='text-sm text-start'>
                        {renderStars(starRating)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {addressLines && (
                    <p className='mt-1 text-[14px] text-start'>
                      {addressLines}
                    </p>
                  )}
                  {latitude && longitude && (
                    <div
                      className='text-primary-700 text-[12px] hover:underline text-start cursor-pointer hover:text-primary-900 w-fit'
                      onClick={() => {
                        setShouldShowHotelMapModal(true);
                      }}
                    >
                      Show on Map
                    </div>
                  )}
                </div>
              </div>

              <div className='flex flex-col sm:flex-row justify-between '>
                <div className='mb-2 sm:mb-0'>
                  <div className='pb-2 text-start'>
                    {freeBreakfast && (
                      <div className='mt-1 text-green-800 font-semibold text-[10px]'>
                        Breakfast included
                      </div>
                    )}
                    {freeCancellation && (
                      <div className='flex gap-2'>
                        <RenderSVG
                          Svg={Check}
                          width='12'
                          height='12'
                          className='text-green-700'
                        />
                        <p className=' text-green-800 font-semibold text-[10px]'>
                          Free Cancellation
                        </p>
                      </div>
                    )}
                    {payAtHotel && (
                      <div className='flex gap-2'>
                        <RenderSVG
                          Svg={Check}
                          width='12'
                          height='12'
                          className='text-green-700'
                        />
                        <p className=' text-green-800 font-semibold text-[10px]'>
                          No prepayment needed - pay at the property
                        </p>
                      </div>
                    )}
                  </div>
                  <div className='flex flex-col border-l-2 pt-1 text-start'>
                    {displayedAmenities?.map((amenity) => (
                      <div key={amenity} className='text-[12px] px-2'>
                        {amenity}
                      </div>
                    ))}
                  </div>

                  <div>
                    {(!isEmpty(hotelPolicy) ||
                      !isEmpty(hotelAmenities) ||
                      !isEmpty(hotelImages)) && (
                      <button
                        onClick={showHotelAmenitiesButton}
                        className='flex text-primary-700 text-xs font-medium whitespace-nowrap pt-2'
                      >
                        {viewMoreHandler() && (
                          <span>
                            {isExpanded
                              ? "View Less"
                              : `View More (+${viewMoreHandler()})`}
                          </span>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col justify-end items-end'>
              <div className='flex md:flex-col md:items-end items-start justify-between mt-2 w-full'>
                {isPriceDifference > 0 && (<p className="text-sm text-red-800 line-through">
                  {currencySymbol}&nbsp;
                  {formatPriceWithCommas(publishedPrice)}
                </p>)}
                <p className='font-semibold text-lg'>
                  {currencySymbol}&nbsp;
                  {formatPriceWithCommas(roomPrice + tavaMarkup)}
                </p>
                {totaltax > 0 ? (<p className='text-[9px] text-gray-800 text-end'>
                  + {currencySymbol} {formatPriceWithCommas(totaltax)} taxes and
                  charges
                </p>) : (
                  <p className='text-[9px] text-gray-800 text-end'>
                    No taxes or charges applied.
                  </p>)}
              </div>
              <button
                className='md:w-fit w-full mt-4 bg-primary-600 text-sm font-medium hover:bg-primary-800 text-white p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600 text-nowrap disabled:cursor-not-allowed disabled:opacity-70'
                onClick={handleSelectHotel}
                disabled={source != "TBO"}
              >
                See availability
              </button>
            </div>
          </div>
        </div>
      </div>

      {shouldShowHotelMapModal && (
        <HotelsMapModal
          hotels={hotelsWithLocation}
          handleClose={handleMapModalClose}
          defaultSelectedHotel={hotelInformation}
        />
      )}
    </div>
  );
};

const Header = ({ resetFilters, setResetFilters, cityName, handleOptionClick, priceSorting, starSorting}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropDownRef = useRef();
  const [showHotelMap, setShowHotelMap] = useState(false);
  const selectedHotelResults = useSelector(selectHotels);
  const filteredHotels = useSelector(selectFilteredHotels);
  const hotelFilters = useSelector(selectHotelsActiveFilters);
  const filtersCount = useSelector(selectHotelsCount);
  const hotelResults = get(selectedHotelResults, "hotelResults", EMPTY_ARRAY);
  const [isFilterReseting, setIsFilterReseting] = useState(false);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

  useClickOutside(dropDownRef, setIsSortDropdownOpen);

  const handleDropdownToggle = () => {
    setIsSortDropdownOpen(!isSortDropdownOpen);
  };
  
  const getFiltersLength = (filters) => {
    if (isEmpty(filters)) return ZERO;
    const differencesCount = size(
      differenceWith(toPairs(INITIAL_HOTEL_FILTERS), toPairs(filters), isEqual)
    );
    return differencesCount;
  };

  useEffect(() => {
    const filterCount = getFiltersLength(hotelFilters);
    dispatch(setHotelsCount(filterCount));
    if (filterCount == 0) setIsFilterReseting(false);
  }, [hotelFilters]);

  const handleResetFilters = () => {
    setIsFilterReseting(true);
    dispatch(setFilteredHotels([]));
    dispatch(setHotelsActiveFilters(INITIAL_HOTEL_FILTERS));
    setResetFilters(true);
  };

  const hotelsWithLocation = filteredHotels.filter(
    (hotel) => !isEmpty(hotel.latitude) && !isEmpty(hotel.longitude)
  );

  const handleMapModalClose = () => setShowHotelMap(false);

  const renderSection = (title, options) => (
    <div>
      <div className="px-4 py-2 text-sm font-medium text-gray-700">
        {title}
      </div>
      {options.map((option) => (
        <button
          key={option.value}
          onClick={() => {
            handleOptionClick(option.value)
            setIsSortDropdownOpen(false)
          }}
          className={classNames(
            "flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100",
            { "bg-gray-100": option.active }
          )}
        >
          <span className="mr-2 text-yellow-700">{option.icon}</span>
          {option.label}
          {option.active && <span className="ml-24 text-green-700">✔</span>}
        </button>
      ))}
    </div>
  );

  return (
    <div
      id='header'
      className='px-6 py-3 self-stretch justify-start items-center gap-6 inline-flex border rounded-md shadow-sm'
    >
      <div className='grow shrink  flex-col justify-center items-start inline-flex'>
        <div className='self-stretch text-base font-semibold leading-7'>
          <div>
            {cityName} {" : "}
            {filteredHotels.length}{" "}
            {filteredHotels.length === ONE
              ? "Property found"
              : "Properties found"}
          </div>
        </div>
      </div>
      <div className='pr-4 justify-start items-center gap-4 flex'>
        {!isEmpty(hotelsWithLocation) && (
          <div
            onClick={() => setShowHotelMap(true)}
            className='lg:hidden text-primary-600 hover:text-primary-700 font-semibold hover:cursor-pointer justify-center items-center gap-2 flex relative'
          >
            Show On Map
          </div>
        )}
        {!!filtersCount && (
          <Spinner
            showSpinner={isFilterReseting}
            spinnerClassName={classNames(
              "w-full border border-contrast-300 rounded-md  bg-white hover:bg-contrast-50 active:bg-white shadow-sm px-4 py-2",
              { "px-12 py-[6px]": isFilterReseting }
            )}
          >
            <button
              className='flex items-center gap-2 text-sm text-contrast-600 font-medium justify-end'
              onClick={handleResetFilters}
              type='button'
            >
              <RenderSVG Svg={Filter} alt='Filter Icon' />
              {t("flightResults.resetFilter")}
            </button>
          </Spinner>
        )}
        {!isEmpty(hotelResults) && (
          <div className='lg:hidden pl-[15px] pr-[17px] py-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center gap-2 z-10 flex relative'>
            <FilterHotelsDropDown
              resetFilters={resetFilters}
              setResetFilters={setResetFilters}
            />
          </div>
        )}
      </div>
      {!isEmpty(filteredHotels) && <div
          ref={dropDownRef}
          className="relative inline-block text-nowrap  text-left z-20"
        >
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500"
            onClick={handleDropdownToggle}
          >
            Sort by
            <RenderSVG
                    Svg={ChevronDown}
                    alt="Toggle dropdown"
                    width="14"
                    height="14"
                    className="ml-2"
                  />
          </button>

          {isSortDropdownOpen && (
            <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {renderSection("STAR", [
                  {
                    label: "Low to High",
                    value: HOTEL_RESULT_SORT_OPTIONS.STAR_ASCENDING,
                    icon: "★",
                    active: starSorting?.includes(HOTEL_RESULT_SORT_OPTIONS.STAR_ASCENDING),
                  },
                  {
                    label: "High to Low",
                    value: HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING,
                    icon: "★",
                    active: starSorting?.includes(HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING),
                  }
                ])}

                {renderSection("PRICE", [
                  {
                    label: "Low to High",
                    value: HOTEL_RESULT_SORT_OPTIONS.PRICE_ASCENDING,
                    icon: "💲",
                    active: priceSorting?.includes(HOTEL_RESULT_SORT_OPTIONS.PRICE_ASCENDING),
                  },
                  {
                    label: "High to Low",
                    value: HOTEL_RESULT_SORT_OPTIONS.PRICE_DESCENDING,
                    icon: "💲",
                    active: priceSorting?.includes(HOTEL_RESULT_SORT_OPTIONS.PRICE_DESCENDING),
                  }
                ])}
              </div>
            </div>
          )}
        </div>}
      {showHotelMap && (
        <HotelsMapModal
          hotels={hotelsWithLocation}
          handleClose={handleMapModalClose}
        />
      )}
    </div>
  );
};

const HotelResults = ({ refContainer }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedHotelResults = useSelector(selectHotels);
  const hotelSearchFilters = useSelector(selectHotelSearchFilters);
  const filteredHotels = useSelector(selectFilteredHotels);
  const hotelFilters = useSelector(selectHotelsActiveFilters);
  const tripStatus = useSelector(selectTripStatus);
  const tenantDetails = useSelector(selectTenantDetails);
  const sortedHotelFilters = useSelector(selectHotelsSortingValues)
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const hotelResults = get(selectedHotelResults, "hotelResults", EMPTY_ARRAY);
  const defaultExpandedValue = !useMediaQuery({ maxWidth: 1000 });
  const [resetFilters, setResetFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const previousFiltersRef = useRef(hotelFilters);
  const containerRef = refContainer;
  const [visibleHotels, setVisibleHotels] = useState([]);
  const [nextIndex, setNextIndex] = useState(0);
  const [shouldShowHotelMapModal, setShouldShowHotelMapModal] = useState(false);
  const hotelsPerPage = 10;

  const loadMoreHotels = () => {
    if (loading) return;

    setLoading(true);
    const newHotels = filteredHotels.slice(
      nextIndex,
      nextIndex + hotelsPerPage
    );
    if (isEmpty(newHotels)) {
      setLoading(false);
      return;
    }
    setVisibleHotels((prev) => {
      const combinedHotels = [...prev, ...newHotels];
      const uniqueHotels = Array.from(
        new Set(combinedHotels.map((hotel) => hotel.resultIndex))
      ).map((resultIndex) =>
        combinedHotels.find((hotel) => hotel.resultIndex === resultIndex)
      );
      return uniqueHotels;
    });

    setNextIndex((prev) => prev + hotelsPerPage);
    setLoading(false);
  };
  
  const [starSorting, setStarSorting] = useState(
    HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING
  );
  const [priceSorting, setPriceSorting] = useState(null);

  const getHotelPriceForSorting = (hotel) => {
    const {
      price: { roomPrice, tavaMarkup },
    } = hotel;
    const totalPrice = roomPrice + tavaMarkup;
    return totalPrice;
  };
  const handleOptionClick = (option) => {
    if (option.includes("price")) {
      setStarSorting(null);
      priceSorting !== option ? setPriceSorting(option) : setPriceSorting(null);
    } else {
      setPriceSorting(null);
      starSorting !== option ? setStarSorting(option) : setStarSorting(null);
    }
  };

  const getSortedHotels = (filteredHotels) => {
    const sortedHotels = [...filteredHotels];

    const sortFunctions = {
      [HOTEL_RESULT_SORT_OPTIONS.STAR_ASCENDING]: (a, b) =>
        a.starRating - b.starRating,
      [HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING]: (a, b) =>
        b.starRating - a.starRating,
      [HOTEL_RESULT_SORT_OPTIONS.PRICE_ASCENDING]: (a, b) =>
        getHotelPriceForSorting(a) - getHotelPriceForSorting(b),
      [HOTEL_RESULT_SORT_OPTIONS.PRICE_DESCENDING]: (a, b) =>
        getHotelPriceForSorting(b) - getHotelPriceForSorting(a),
    };

    const compareFunctions = [];
    if (!starSorting && !priceSorting) {
      compareFunctions.push(
        sortFunctions[HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING]
      );
    }
    if (starSorting) {
      if (compareFunctions.length === ONE) compareFunctions.pop();
      compareFunctions.push(sortFunctions[starSorting]);
    }
    if (priceSorting) {
      if (compareFunctions.length === ONE) compareFunctions.pop();
      compareFunctions.push(sortFunctions[priceSorting]);
    }

    return sortedHotels.sort((a, b) => {
      for (const compareFunction of compareFunctions) {
        const result = compareFunction(a, b);
        if (result !== 0) return result;
      }
      return 0;
    });
  };
  const handleScroll = () => {
    if (!containerRef.current) return;
    const scrollTop = containerRef.current.scrollTop;
    const containerHeight = containerRef.current.clientHeight;
    const scrollHeight = containerRef.current.scrollHeight;
    if (scrollTop + containerHeight >= scrollHeight - 100) {
      setLoading(true);
      loadMoreHotels();
    }
  };

  useEffect(() => {
    if (isEmpty(filteredHotels)) {
      setVisibleHotels([]);
      return;
    }
    const sortingResult = getSortedHotels(filteredHotels);
    setVisibleHotels(sortingResult.slice(0, hotelsPerPage));
    setNextIndex(0);
    loadMoreHotels();
  }, [filteredHotels]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [nextIndex, filteredHotels]);

  const { hotelSearchQuery } = tripStatus;
  const getHotelPrice = (hotel) => {
    const {
      price: { roomPrice, tax, tavaMarkup, otherCharges },
    } = hotel;
    const totalPrice = roomPrice + tax + tavaMarkup + otherCharges;
    return totalPrice;
  };
  const { brandName } = tenantConfig;

  const getQueryParams = (url) => {
    const params = new URLSearchParams(url);
    return {
      hotelCode: params.get("hotelCode"),
      destinationType: params.get("destinationType"),
      hotelName: params.get("hotelName"),
      cityName: params.get("cityName"),
      countryCode: params.get("countryCode"),
    };
  };

  const isSearchInvalid = (params) => {
    const invalidValues = ["undefined", "NaN"];
    const isInvalid = (value) => invalidValues.includes(String(value).trim());

    const hotelCode = params.hotelCode;
    const destinationType = params.destinationType;
    const hotelName = params.hotelName;
    const cityName = params.cityName;
    const countryCode = params.countryCode;

    return (
      isInvalid(hotelCode) ||
      isInvalid(destinationType) ||
      isInvalid(hotelName) ||
      isInvalid(cityName) ||
      isInvalid(countryCode)
    );
  };
  const params = getQueryParams(search);

  const invalidSearch = isSearchInvalid(params);

  const renderEmptyLayout = () => {
    return (
      <div className='m-auto w-full overflow-hidden'>
        {invalidSearch || !search ? (
          <EmptyLayout />
        ) : (
          <NoHotelResults totalHotels={hotelResults.length} />
        )}
      </div>
    );
  };
  
  useEffect(() => {
    if (hotelResults.length) {
      dispatch(setHotelsActiveFilters(INITIAL_HOTEL_FILTERS));
      const updatedSortingValues = hotelResults.reduce((values, hotel) => {
        const price = getHotelPrice(hotel);
        const location = hotel.hotelLocation;
        const stars = hotel.starRating;
        const category = hotel.hotelCategory;
        values.price.minPrice = values.price.minPrice
          ? Math.min(values.price.minPrice, Math.floor(price))
          : Math.floor(price);
        values.price.maxPrice = values.price.maxPrice
          ? Math.max(values.price.maxPrice, Math.ceil(price))
          : Math.ceil(price);
        location &&
          (values.locality[location] =
            (values.locality[location] || ZERO) + ONE);
        stars &&
          (values.starRatings[stars] =
            (values.starRatings[stars] || ZERO) + ONE);
        category &&
          (values.categories[category] =
            (values.categories[category] || ZERO) + ONE);
        return values;
      }, cloneDeep(INITIAL_HOTEL_VALUES));
      updatedSortingValues.price.currency = get(
        hotelResults[ZERO],
        "price.currencyCode",
        EMPTY_STRING
      );
      dispatch(setHotelsSortingValues(updatedSortingValues));
    }

    if (!isEmpty(hotelSearchQuery) && !isEmpty(hotelSearchFilters)) {
      let maxAmount;
      if(sortedHotelFilters) {
        const hotelPrice = sortedHotelFilters?.price?.maxPrice ;
        maxAmount = hotelPrice < hotelSearchFilters.maxPrice ? hotelPrice : hotelSearchFilters.maxPrice; 
      }
      const starRating = hotelSearchFilters.starRating;  
      dispatch(
        setHotelsActiveFilters({
          ...hotelFilters,
          price: { minPrice: "", maxPrice: maxAmount },
          starRatings: starRating ? starRating : [],
          categories: [],
          locality: [],
        })
      );      
    }
  }, [hotelResults]);

  useEffect(() => {
    if (!hotelResults) return;
      const filteredHotelResults = hotelResults.filter((hotel) => {
        const {price: { roomPrice }} = hotel;
        const price = getHotelPrice(hotel);
        const location = hotel.hotelLocation;
        const stars = hotel.starRating;
        const category = hotel.hotelCategory;

        const locationCheck =
        !isEmpty(hotelFilters.locality) &&
        !hotelFilters.locality.includes(location);
        const starsCheck =
          !isEmpty(hotelFilters.starRatings) &&
          !hotelFilters.starRatings.includes(String(stars));
        const categoryCheck =
          !isEmpty(hotelFilters.categories) &&
          !hotelFilters.categories.includes(category);
        const maxPriceCheck =
          hotelFilters.price.maxPrice && price >= hotelFilters.price.maxPrice;
        const minPriceCheck =
          hotelFilters.price.minPrice && roomPrice <= hotelFilters.price.minPrice;

        return !(
          locationCheck ||
          starsCheck ||
          categoryCheck ||
          maxPriceCheck ||
          minPriceCheck
        );
      });      

      dispatch(setFilteredHotels(filteredHotelResults));
  }, [hotelFilters, hotelResults]);

  useEffect(() => {
    dispatch(setFilteredHotelResult(hotelResults));
  }, [hotelSearchFilters, hotelResults]);
  
  useEffect(() => {
    const sortingResult = getSortedHotels(filteredHotels);
    setVisibleHotels(sortingResult);
  }, [starSorting, priceSorting]);

  useEffect(() => {
    let key = "isHotelSearchInitiated";
    let value = true;
    !isEmpty(hotelSearchQuery) && dispatch(setTripStatus({ key, value }));
  }, []);

  const { cityName } = getObjectFromQueryParams(search);

  return (
    <div className='flex-grow h-full mx-auto bg-white'>
      <Helmet>
        <title>{brandName} | Hotels</title>
      </Helmet>

      <main className='relative pt-4 bg-white'>
        <div className='sticky top-14 px-8 p-3 -mt-4 border-b z-30 bg-white border-contrast-200'>
          <HotelSearch
            showEditSearch={true}
            defaultExpandedValue={defaultExpandedValue}
          />
        </div>
        <div className='container-hotel px-4 md:px-24 mx-auto'>
          <div className='pt-3 h-full flex flex-col align-middle relative m-auto text-center'>
            <Spinner
              name={[FETCH_HOTEL_RESULTS, FETCH_HOTEL_STATIC_DATA]}
              loaderComponent={<HotelLoader />}
              showSkeleton={true}
              showSpinner={
                !isEmpty(hotelResults) &&
                isEmpty(filteredHotels) &&
                isEmpty(hotelFilters)
              }
            >
              <div className='flex flex-row gap-2 w-full items-start'>
                {!isEmpty(hotelResults) && (
                  <div className={classNames('hidden lg:block mr-2 sticky top-36',
                    {"z-[100]": shouldShowHotelMapModal}
                  )}>
                    <FilterHotelsDropDown
                      resetFilters={resetFilters}
                      setResetFilters={setResetFilters}
                      showSideFilters={true}
                      filteredHotels={filteredHotels}
                      shouldShowHotelMapModal={shouldShowHotelMapModal}
                      setShouldShowHotelMapModal={setShouldShowHotelMapModal}
                    />
                  </div>
                )}
                <div className='flex flex-col gap-2 w-full'>
                  <div className='bg-white border-b-0 border-contrast-200 rounded-lg text-start flex-col justify-start items-start gap-6 inline-flex ml-2'>
                    {!isEmpty(selectedHotelResults) && (
                      <Header
                        cityName={cityName}
                        resetFilters={resetFilters}
                        setResetFilters={setResetFilters}
                        handleOptionClick={handleOptionClick}
                        priceSorting={priceSorting}
                        starSorting={starSorting}
                      />
                    )}
                  </div>

                  <div className='h-full w-full'>
                    {!isEmpty(filteredHotels) ? (
                      <div className='h-full pl-2 self-stretch flex-col justify-start items-start gap-2 flex '>
                        {!shouldShowHotelMapModal && visibleHotels.map((hotelInformation) => (
                          <HotelResultCard
                            key={hotelInformation.resultIndex}
                            hotelInformation={hotelInformation}
                          />
                        ))}
                      </div>
                    ) : (
                      renderEmptyLayout()
                    )}
                  </div>
                </div>
              </div>
            </Spinner>
          </div>
        </div>
      </main>
    </div>
  );
};
export default HotelResults;
