export const MODALS = {
  API_FAILURE_MODAL: "apiFailureModal",
  LAST_NAME_AMENDMENT_MODAL: "LastNameAmendmentModal",
  FLIGHT_REISSUANCE_MODAL: "flightReIssuanceModal",
  REFUND_MODAL: "refundModal",
  SESSION_EXPIRED_MODAL: "sessionExpiredModal",
  BOOKING_CANCELLATION_MODAL: "bookingCancellationModal",
  AMENITIES_MODAL: "amenitiesModal",
  HOTEL_DETAILS_CHANGE_MODAL: "hotelDetailsChangeModal",
  HOTEL_FACILITIES_MODAL: "hotelFacilitiesModal",
  CAROUSAL_IMAGE_MODAL: "carousalImageModal",
  BAGGAGE_SELECTION_MODAL: "baggageSelectionModal",
  HOTEL_CANCELLATION_MODAL: "hotelCancellationModal",
  FLIGHT_CANCELLATION_MODAL: "flightCancellationModal",
  FLIGHT_OPTIONS_MODAL: "flightOptionsModal",
  VIEW_FLIGHT_DETAILS_MODAL: "viewFlightDetailsModal",
  APPROVALS_MODAL: "approvalsModal",
  REJECTION_MODAL: "rejectionModal",
  APPROVAL_FILTERS_MODAL: "approvalFiltersModal",
  DELETE_CONFIRMATION_MODAL: "deleteConfirmationModal",
  SUMMARY_MODAL: "summaryModal",
  CREATE_TRIP_MODAL: "createTripModal",
  MANAGE_TRIP_MODAL: "manageTripModal",
  TRIP_PAYMENT_MODAL: "tripPaymentModal",
  HOTEL_VIEW_MORE_MODAL: "hotelViewMoreModal",
  MANAGE_PASSWORD_MODAL: "managePasswordModal",
  PAY_LATER_MODAL: "payLaterModal",
};
